import { ActionTypes as ConversationActionTypes } from "../../headless-chat/storage/redux/actions/conversation.actions"
import { SetHideFakeChatContainer } from "../actions/app.actions";

/**
 * @returns {function(*): function(*=): function(...[*]=)}
 */
const appMiddleware = () => {
  
    return store => next => action => {
      
      const { type, payload } = action; // Payload can be undefined
      
        if( type === ConversationActionTypes.RemoveConversation ) {
          
            const state = store.getState();
            if ( state.chat.conversations.active === payload ) {
              store.dispatch(SetHideFakeChatContainer(false));
            }
            
          return next(action);
            
        } else {
            return next(action);
        }
        
    }
    
};

export default appMiddleware;