export const DemoFeatures = () => {
    return (
        <>
            <h2 className="mt-4 mb-3">Demo features</h2>
            <ul>
                <li>login to any credentials</li>
                <li>after login, your username is visible to all connected users as a contact</li>
                <li>chat with all connected users</li>
                <li>automatically generated avatar with user initials</li>
                <li>fully responsive</li>
                <li>can be installed (added to home screen) as a <abbr title="Progressive Web Application">PWA</abbr></li>
                <li>notification of a new version when it becomes available</li>
                <li>typing indicator</li>
                <li>animations when changing views</li>
                <li>unread message counter</li>
                <li>message drafts</li>
                <li>permanent "Leave feedback" contact</li>
                <li>personalized messages from permanent contacts</li>
            </ul>
        </>
    )
}
