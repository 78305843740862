import {useState, useEffect, useRef} from "react";
import {useDispatch, useSelector} from 'react-redux'
import {LoginView} from "../views";
import {SetAuthState} from "../redux/actions/auth.actions";
import {SetUserData} from "../redux/actions/user.actions";
import {v4 as uuidv4} from "uuid";
import {useA2HS} from "../hooks/useA2HS";
import {InstallBanner} from "../components";
import {SetInstallBannerState} from "../redux/actions/installBanner.actions";
import {autoAvatar} from "../utils/avatar";
import {
  predefinedContacts,
  predefinedMessages,
  feedbackPredefinedMessages,
  predefinedUsers
} from "../models/PredefinedContacts";
import {useMediaQuery} from "../hooks";
import {smallScreenMQ} from "../utils/responsive";
import {push} from "connected-react-router";
import {MessageContentType, Presence, useChat, User, UserStatus} from "@chatscope/use-chat";
import {InvalidCredentialsException} from "../lib/Exceptions";
import {useConfig} from "../init";

const markWord = word => `<strong style="color:#FF5F5F">${word}</strong>`;

export const LoginContainer = () => {

  const [inputsDisabled, setInputsDisabled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const dispatch = useDispatch();

  const countUserIconClickRef = useRef(0);
  
  // TODO: Do osobnego hooka
  useEffect(() => {
    
    const togglePassword = evt => {
      if ( evt.altKey === true && evt.key === "P" ) {
        setShowPassword(!showPassword);
      }
    };
    
    window.addEventListener("keydown", togglePassword);
    
    return () => window.removeEventListener("keydown", togglePassword);
  },[showPassword]);
  
  
  //const { config: configService } = useServices();
  const { config } = useConfig();

  const {handleInstallClick, handleDismissClick} = useA2HS((state) => dispatch(SetInstallBannerState(state)));

  const [installBanner] = useSelector(state => [state.installBanner.state]);
  
  const { setActiveConversation, addUser, addConversation, addMessage, service: api, setCurrentUser } = useChat();
  
  const handleUserIconClick = () => {
    
    countUserIconClickRef.current += 1;
    if ( countUserIconClickRef.current > 4 ) {
      countUserIconClickRef.current = 0;
      setShowPassword(!showPassword);
    }
  };

  const match = useMediaQuery(smallScreenMQ);
  
  const handleLoginClick = async ({username, password}) => {

    setInputsDisabled(true);
    if ( invalidCredentials ) {
      setInvalidCredentials(false);
    }
    
    const user = {
      id: uuidv4(),
      username,
      firstName: "",
      lastName: "",
      status: UserStatus.Available,
      statusDescription: "",
      avatar: autoAvatar(username),
      permissions: []
    };
    
    // TODO: Czy to jest w ogóle do czegoś teraz potrzebne, jeżeli currentUser jest trzymany w useChat?
    // Właściwie, to w tej apce prawdopodobnie nie, ale w większej może być tak, że będzie to potrzebne
    // Bo redux będzie ze względów wydajnościowych używany wyżej niż sam chat
    dispatch(SetUserData(user));
    
    try {
      
      if ( config.adapter.name === "simple-chat" ) {

        //const simpleChatService = api.service as SimpleChatAdapter;
        const simpleChatService = api.service;
        
        await simpleChatService.login({
          username,
          password
        }).then((res) => {
          if (!res.result) {
            throw new InvalidCredentialsException();
          }
          return api;
        });
        
        await simpleChatService.connect();

        // TODO: Po chuj to jest?
        // Jeżeli się zalogowałem już jako jakiś user, to dlaczego mam wysyłać swoje dane jeszcze raz?
        // One powinny przyjść z serwera
        // Bo teraz mogę oszukiwać
        // A z drugiej strony, mogę logować się anonimowo, więc....
        // Może niech serwer to rozpoznaje
        const registerResponse = await simpleChatService.register({
          username: user.username,
          avatar: user.avatar,
          status: user.status
        });
        
        // To w sumie to mogło by wylecieć do adaptera
        setCurrentUser(new User({
          id: registerResponse.result.id,
          username: registerResponse.result.username || user.username,
          avatar: registerResponse.result.avatar || user.avatar,
          email:"",
          firstName:"",
          lastName:"",
          bio:"",
          presence: new Presence({
            status: registerResponse.status,
            description: ""
          })
        }));

      } else {
        
        //const simpleChatService = api.service as ChatService;
        const chatService = api.service;
        const registerResponse = await chatService.register(user, password);
        
        // TODO: UserId should be created by the server

        setCurrentUser(new User({
          id: user.id,
          username: registerResponse.result.username || user.username,
          avatar: registerResponse.result.avatar || user.avatar,
          email:"",
          firstName:"",
          lastName:"",
          bio:"",
          presence: new Presence({
            status: UserStatus.Available,
            description: ""
          })
        }));
      }

      predefinedUsers.forEach( u => {
        addUser(u);
      });

      // Add predefined contacts and messages
      predefinedContacts.forEach( c => {
        addConversation(c);
      });

      predefinedMessages.forEach( m => {
        
        
        const payload = m.message.model.type === MessageContentType.TextPlain || m.message.model.type === MessageContentType.TextHtml ?
          m.message.model.payload.replace(":username:", markWord(username)): m.message.model.payload;
        
        addMessage({
          direction: m.message.direction,
          senderId: m.message.senderId,
          contentType: m.message.model.type,
          content: payload
        }, m.conferenceId, true );

      });

      feedbackPredefinedMessages.forEach( m => {
        
        const payload = m.message.model.type === MessageContentType.TextPlain || m.message.model.type === MessageContentType.TextHtml ?
          m.message.model.payload.replace(":username:", markWord(username)) : m.message.model.payload; 
        
        addMessage({
          direction: m.message.direction,
          senderId: m.message.senderId,
          contentType: m.message.model.type,
          content: payload
        }, m.conferenceId, true );

      });

      // END: Add predefined contacts and messages

      await api.getContacts();

      setInputsDisabled(false);
      dispatch(SetAuthState(true));

      if ( config.adapter === "simple-chat" ) {
        // For simple chat we need to get conversations separately
        await api.getConversations();
      }

      if ( match === false ) {
        // Set first conversation as active only on big screen
        setActiveConversation({conversationId: predefinedContacts[0].id});
        dispatch(push("/chat"));
      }
      
    } catch (e) {
        console.error(e);
      if ( e instanceof InvalidCredentialsException ) {
        setInputsDisabled(false);
        setInvalidCredentials(true);
      }

      }
      
  };

  return (<>
    <LoginView onLoginClick={handleLoginClick} inputsDisabled={inputsDisabled} loading={inputsDisabled}
               showPassword={showPassword}
               forgotPassword={false}
               register={false}
               usernamePlaceholder="Your name"
               passwordPlaceholder="Your password"
               showInfo={false}
               onUserIconClick={handleUserIconClick}
               invalidCredentials={invalidCredentials}
    />
    {installBanner && <InstallBanner onInstallClick={handleInstallClick} onDismissClick={handleDismissClick}/>}
  </>);

};


export default LoginContainer;