import PropTypes from 'prop-types';
import { ConversationHeader} from "@chatscope/chat-ui-kit-react";
import {useChat} from "@chatscope/use-chat";
import noop from "../../utils/noop";
import {useGetParticipant} from "../../hooks/useGetParticipant";

export const CurrentConversationHeader = ({onBackClick}) => {
  
  const { activeConversation } = useChat();
  
  const { avatar, name } = useGetParticipant(activeConversation);
  
    return (
      <ConversationHeader className="chat-conversation-header">
        <ConversationHeader.Back onClick={onBackClick} title="Go back to contact list"/>
        {avatar}
        <ConversationHeader.Content userName={name} info="" />
      </ConversationHeader>
    );
};

CurrentConversationHeader.propTypes = {
  onBackClick: PropTypes.func
};

CurrentConversationHeader.defaultProps = {
  onBackClick: noop
};

export default CurrentConversationHeader;