import { useState, useEffect } from "react";

export const useMediaQuery = mediaQuery => {

  const [match, setMatch] = useState(!(window.matchMedia(mediaQuery).matches === false));
  
  useEffect(() => {

    const mediaQueryList = window.matchMedia(mediaQuery);
    const handleMediaQueryChange = evt => setMatch(!(evt.matches === false));
    
    if (typeof mediaQueryList.addEventListener === "function") { // Normal browser
      mediaQueryList.addEventListener("change", handleMediaQueryChange);
    } else if (typeof mediaQueryList.addListener === "function") { // Safari, IE
      mediaQueryList.addListener(handleMediaQueryChange);
    }

    return () => {
      if (typeof mediaQueryList.addEventListener === "function") { // Normal browser
        mediaQueryList.removeEventListener("change", handleMediaQueryChange);
      } else if (typeof mediaQueryList.addListener === "function") { // Safari, IE
        mediaQueryList.removeListener(handleMediaQueryChange);
      }
    }
    
  },[mediaQuery]);
  
  return match;
  
};