import PropTypes from "prop-types";

export const FakeChatContainer = ({children}) => <div className="cs-chat-container">{children}</div>; 
    

FakeChatContainer.propTypes = {
    children: PropTypes.node
};

FakeChatContainer.defaultProps = {
    
};

export default FakeChatContainer;