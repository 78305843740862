import {useContext, createContext} from "react";
import PropTypes from "prop-types";
import {useStore} from "react-redux";

const ServicesContext = createContext();
ServicesContext.displayName = "Services";

const useServices = () => useContext(ServicesContext);

const ServicesProvider = ({children, services}) => {
    
    const store = useStore();
    
    return <ServicesContext.Provider value={services(store)}>{children}</ServicesContext.Provider>;
    
};

ServicesProvider.propTypes = {
    children: PropTypes.node,
  /**
   * Function which returns services as object indexed by service name
   * As parameter this function receives redux store
   */
    services: PropTypes.func
};


export { ServicesContext, ServicesProvider, useServices };