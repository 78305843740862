import {useMemo} from "react";
import type {MouseEvent} from "react";
import {Avatar, Button, ConversationHeader} from "@chatscope/chat-ui-kit-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { statusMap } from "../../utils/statusMap";
import {useChat} from "@chatscope/use-chat";

interface ConversationListHeaderProps {
  onLogoutClick?: (evt:MouseEvent<HTMLButtonElement>) => void;  
}

export const ConversationListHeader = ({onLogoutClick}:ConversationListHeaderProps) => {
  
  const {currentUser} = useChat();
  
  const header = useMemo(() => {

    const status = currentUser ? statusMap[currentUser?.presence.status] : undefined;
    
    return (
      <ConversationHeader className="chat-conversation-header">
        <Avatar src={currentUser?.avatar} name={`${currentUser?.firstName} ${currentUser?.lastName}`} status={status} />
        <ConversationHeader.Content userName={currentUser?.username} info={currentUser?.presence.description} />
        <ConversationHeader.Actions>
          <Button type="button" icon={<FontAwesomeIcon icon={faSignOutAlt} />} onClick={onLogoutClick} className="chat-conversation-header__logout-btn" title="Logout" />
        </ConversationHeader.Actions>
      </ConversationHeader>);
    },[currentUser, onLogoutClick]);
  
    return header;
};


export default ConversationListHeader;