import { Chatscope } from "../../components/Branding";

export const Branding = () => {
    return (
        <div className="d-flex flex-row justify-content-between align-items-stretch pt-4 mt-4 border-top">
          {/*<Creatext className="d-flex flex-row ml-auto" size="md"/>*/}
          <Chatscope className="d-flex flex-row ml-auto align-items-center"/>
        </div>
    );
};

export default Branding;