import { randomColor } from "randomcolor";

export const autoAvatar = username => {
   
    const can = document.createElement("canvas");
    can.width = 42;
    can.height = 42;

    const ctx= can.getContext("2d");

    ctx.rect(0,0,42,42);

    const color = randomColor();
    
    ctx.fillStyle = color;
    ctx.fill();
    
    ctx.fillStyle = "white";
    ctx.font=`bold 22px "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif`;
    ctx.direction = "ltr";
    ctx.textAlign = "center";
    ctx.textBaseline = "top";
    
    const letters = (name => {
        const splitted = name.split(" ");
        // If username has more than one word - get first letters from first two words
        
        if ( splitted.length > 1 ) {
            const next = splitted.slice(1).find( f => f !== "");
            if ( typeof next !== "undefined" ) {
                return `${splitted[0][0].toUpperCase()} ${next[0].toUpperCase()}`;    
            }
        } else { // For one word, get first two letters from this word

            if (name.length > 1) {
                return `${name[0].toUpperCase()} ${name[1].toUpperCase()}`;
            } else {
                return `${name[0].toUpperCase()}`;
            }
            
        }
    })(username.trim());

    ctx.fillText(letters,21,11, 24);

    const url = can.toDataURL();

    return url;

};