import {Conversation, useChat} from "@chatscope/use-chat";
import {useMemo} from "react";
/* @ts-ignore */
import {Avatar} from "@chatscope/chat-ui-kit-react";
import {statusMap} from "../utils/statusMap";

export const useGetParticipant = (conversation:Conversation) => {
    
    const {getUser, currentUser, users} = useChat();

    // Memo depends on users collection, because we need to change user status etc. after update received from server
    // TODO: How to change it to remove warning about unnecessary users dependency?
    return useMemo(() => {
        // TODO: Tutaj participantów może być więcej niż jeden (jeżeli to jest grupa, albo simple-chat)
        // Dlatego trzeba to obsłużyć
        // Poza tym nie można tutaj brać pierwszego elementu tablicy
        // TYlko trzeba znaleźć ten, który nie jest mną
        // Do tego powinna być metoda biblioteki, która zwróci tylko tych, którzy nie są mną (może być po porstu flaga jako parametr funkcji, czy zwracać z pominięciem)
        // ALle chwilowo zrobię ręcznie
        const participants = conversation?.participants.filter( p => p.id !== currentUser?.id);

        if (participants.length === 1 ) { // One-on-one
            const user = getUser(participants[0].id);

            if ( user ) {
                return {
                    avatar: <Avatar src={user.avatar} status={statusMap[user.presence.status]} />,
                    name: user.username
                };
            }

        } else {
            // TODO: Obsłużenie rozmowy grupowej
        }

        return {
            avatar: undefined,
            name: undefined
        };
        
    },[conversation, currentUser, getUser, users]);
    
    
}