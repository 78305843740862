import PropTypes from "prop-types";
import type {ConfettiConfig} from "dom-confetti";

// Fix confetti types
declare module "dom-confetti" {

    export function confetti(root: HTMLElement, config?: ConfettiConfig): Promise<void>;


}

export const userType = PropTypes.shape({ id: PropTypes.string, username: PropTypes.string, firstName: PropTypes.string, lastName: PropTypes.string, statusDescription: PropTypes.string } );