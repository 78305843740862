import PropTypes from "prop-types";
import chatscopeLogo from "./chatscope_logo_alpha.svg";

export const Chatscope = ({className, ...rest}) => {
    return (
        <div className={className} {...rest}>
            <div className="text-muted d-flex flex-column flex-md-row align-items-md-center"><span>UI components provided by:</span>
                <div className="mt-2 align-self-end mt-md-0 ml-md-2 d-flex align-items-center">
                    <a href="https://chatscope.io" rel="noopener noreferrer" className="ml-2" target="_blank">
                      <img src={chatscopeLogo} alt="Creatext logo" style={{maxWidth:"32px"}} />
                    </a>
                    <a href="https://chatscope.io" rel="noopener noreferrer" target="_blank"
                       className="ml-2" style={{color:"#44A0FB", fontSize: "1.2em", fontWeight: "normal", textDecoration:"none"}}>chatscope</a>
                </div>
            </div>
        </div>
    );
};

Chatscope.propTypes = {
  className: PropTypes.string
};

Chatscope.defaultProps = {};

export default Chatscope;