import {ActionTypes as ConversationActionTypes } from "../actions/conversation.actions";
import {ActionTypes as MessagesActionTypes } from "../actions/message.actions";

export const reducer = (st, action) => {

  const state = st?.conversations;
  
  const defaultState = {
    currentUserDisconnected: false,
    active: undefined,
    activeSetBy: undefined,
    items: []
  }; 
  
  if (typeof  state === "undefined") {
    return {...defaultState};
  }

  const payload = action.payload;
  
  switch (action.type) {
    
    case ConversationActionTypes.AddConversation:
      return  {
        ...state,
        items: [...state.items].concat(action.payload)
      };
      
    case ConversationActionTypes.RemoveConversation: {

      const disconnectedId = action.payload;
      
      return {
        currentUserDisconnected: state.active === disconnectedId,
        active: state.active,
        items: state.items.filter(c => c.id !== disconnectedId)
      };
      
    }
        
    case ConversationActionTypes.SetActiveSetBy: {
      return {
        ...state,
        activeSetBy: payload
      };
    }
    
    case ConversationActionTypes.SetActive: {
      
      // Backward compatibility
      const active = typeof payload === "object" ? payload.id : payload;
      const activeSetBy = payload?.setBy;
      
      // Undefined means that no user is active
      if ( typeof active === "undefined" ) {
        return {
          ...state,
          active,
          activeSetBy
        };
      }
      
      // Reset unread counter
      const currentItems = state.items;
      const idx = currentItems.findIndex(i => i.id === active );
      
      if (idx !== -1) {
        
        const currentItem = currentItems[idx];
        const unreadCnt = 0;
        const newConversation = {...currentItem, unreadCnt};
        const newItems = currentItems.slice(0, idx).concat(newConversation).concat(currentItems.slice(idx + 1));
        
        return {
          ...state,
          currentUserDisconnected: false,
          active,
          activeSetBy,
          items: newItems,
        };
        
      } else {
        
        return {
          ...state,
          active,
          activeSetBy
        };
      }
      
    }
      
    case ConversationActionTypes.SetCurrentMessage: {

      const {conversationId, message} = payload;

      const currentItems = state.items;
      const idx = currentItems.findIndex(i => i.id === conversationId);

      const newItems = (() => {
        if (idx !== -1) {
          
          const newConversation = {...currentItems[idx], currentMessage: message};
          
          return currentItems.slice(0, idx).concat(newConversation).concat(currentItems.slice(idx + 1));
        } else {
          return [...currentItems];
        }
      })();


      return {
        ...state,
        items: newItems,
      };
    }
      
    case ConversationActionTypes.SetTypingUser: {
      
      const { userId, text, typing } = payload;
      const currentItems = state.items;
      const idx = currentItems.findIndex(i => i.id === userId);
      
      const newItems = (() => {

        if ( typing === true ) {
          
          // Lokalnie id konferencji to jest id zdalnego usera, dlatego szukamy tutaj konferenccji po userId a nie po conversationId
          // Docelowo będzie to zmienione i konferencje będą miały swoje dedykowane identyfikatory

          if (idx !== -1) {
            const currentItem = currentItems[idx];
            const newTyping = new Map(Array.from(currentItem.typingUsers));
            newTyping.set(userId, text);
            const newConversation = {...currentItem, typingUsers: newTyping};
            return currentItems.slice(0, idx).concat(newConversation).concat(currentItems.slice(idx + 1));
          } else {
            return [...currentItems];
          }

        } else {
          // Lokalnie idkoferencji to jest id zdalnego usera, dlatego szukamy tutaj konferenccji po userId a nie po conversationId
          // Docelowo będzie to zmienione i konferencje będą miały swoje dedykowane identyfikatory
          if ( idx !== -1 ) {
            const currentItem = currentItems[idx];
            const newTyping = new Map(Array.from(currentItem.typingUsers));
            newTyping.delete(userId);
            const newConversation = {...currentItem, typingUsers: newTyping};
            return currentItems.slice(0, idx).concat(newConversation).concat(currentItems.slice(idx + 1));
          } else {
            return [...currentItems];
          }
        }
        
      })();
      
      return {
        ...state,
        items: newItems,
      };
      
    }
    
    case MessagesActionTypes.AddMessage: {
      
      const { conversationId } = payload;
      
      // If message arrives from inactive conversation - increase unread number
      if ( conversationId !== state.active ) {

        const currentItems = state.items;
        const idx = currentItems.findIndex(i => i.id === conversationId );
        if (idx !== -1) {

          const currentItem = currentItems[idx];
          const unreadCnt = currentItem.unreadCnt + 1;
          const newConversation = {...currentItem, unreadCnt};
          const newItems = currentItems.slice(0, idx).concat(newConversation).concat(currentItems.slice(idx + 1));
          
          return {
            ...state,
            items: newItems,
          };
        } else {
          return {...state};
        }
        
      } else {
        return {...state};
      }
      
    }
    
    case ConversationActionTypes.SetCurrentUserDisconnected: {
      
      return {...state, currentUserDisconnected: payload};
      
    }
    
    case ConversationActionTypes.ResetState: {

      return {...defaultState};
      
    }
    
    default:
      return {...state};
      
  }

};
