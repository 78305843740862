import {useState, useRef, lazy, useEffect} from "react";
import PropTypes from "prop-types";
import {
  MainContainer,
  Sidebar,
  ChatContainer,
  ConversationHeader,
  Message,
  MessageGroup,
  MessageList,
  TypingIndicator,
  MessageInput,
} from "@chatscope/chat-ui-kit-react";
import noop from "../../utils/noop";
import { usePrevious } from "../../hooks/usePrevious";
import FakeChatContainer from "./FakeChatContainer";
import Welcome from "./Welcome";
import { removeHint, matchHint} from "../../utils/hints";
import {cleanMsg} from "../../utils/sanitize";
import {userType} from "../../types";
import { ConversationListHeader } from "../ConversationListHeader";
import ConfettiMessage from "./ConfettiMessage";
import { Presentation as CreatextPresentation } from "../Branding/Creatext/Presentation";
import { HelpConversation } from "../Help/HelpConversation";
import classNames from "classnames";
import {MessageContentType, useChat} from "@chatscope/use-chat";
import CurrentConversationHeader from "./CurrentConversationHeader";
import {useTypingIndicator} from "../../hooks/useTypingIndicator";

const ConversationsView = lazy( () => import("../../views/ConversationsView") );

const getMessageType = (contentType) => {
  if ( contentType === MessageContentType.TextPlain ) {
    return "text";
  } else if ( contentType === MessageContentType.Other ) {
    return "custom";
  } else {
    return "html";
  }
};

export const Chat = ({ user, conversations, messages, onSend, onBack, onConversationChange, activeConversation,
                       onLogout, activeSetBy, onChange, hint, currentUserDisconnected, animating, hideFakeChatContainer, 
                       suggestionsEnabled }) => {
   
    const inputRef = useRef();
    const brandIconRef = useRef();
    const [message,setMessage] = useState("");
    const internalHint = useRef();
    
    const {currentMessage} = useChat();
    
    useEffect( () => {
        internalHint.current = hint;
    },[hint]);
    
    const previousConversation = usePrevious(activeConversation);
    const previousAnimating = usePrevious(animating);
    
    /**
     * @param {Object} hint Hint object
     * @param {String} hint.source
     * @param {String} hint.suggestion
     */
    const insertHint = hint => {
      
        console.log(`[insertHint] ${hint.suggestion}`);
        
        if ( typeof hint.suggestion === "string" ) {

            const sel = window.getSelection();
            
            if (sel.rangeCount > 0) {

                const range = sel.getRangeAt(0);
                
                if (range.collapsed === true) {
                    
                    const existedHint = range.commonAncestorContainer.parentNode.querySelector("[data-hint]");
                    console.log("Existed: ", existedHint);
                    
                    if( existedHint ) {
                        
                        if ( hint.suggestion.length > 0 ) {
                            existedHint.innerHTML = hint.suggestion;
                            // Tutaj musimy range przesunąć przed hint, bo wcześniej setMessage spowodowało, że automatycznie ustawił się za hintem
                            const newRange = document.createRange();
                            newRange.setStartBefore(existedHint);
                            sel.removeAllRanges();
                            sel.addRange(newRange);
                        } else { // Jeżeli hint jest pusty to go usuwam
                            existedHint.parentNode.removeChild(existedHint);
                        }
                        
                    } else {

                        if ( hint.suggestion.length > 0 ) {
                            const hintNode = document.createElement("span");
                            hintNode.setAttribute("data-hint", "");
                            hintNode.setAttribute("contenteditable", "false");
                            hintNode.style.color = "rgba(0,0,0,0.6)";
                            hintNode.style.display = 'inline-block';
                            hintNode.style.whiteSpace = "pre";

                            console.log("insering hint");
                            hintNode.innerHTML = hint.suggestion;
                            range.insertNode(hintNode);

                            sel.collapseToStart();

                            if ( brandIconRef.current ) {
                              brandIconRef.current.blink();
                            }
                          
                        }
                    }
                }

            }
        }
    };
    
    const handleKeyDown = evt => {
      console.log("[handleKeyDown]");
      
        if ( suggestionsEnabled === true ) {
          if (evt.key === "Tab" && internalHint.current?.suggestion?.length > 0) {
            evt.preventDefault();
            // Trzeba tutaj zmatchować hint ponieważ doszła tutaj jeszcze jedna literka po naciśnięciu klawisza
            const [matchedHint] = matchHint(message, internalHint.current);
            console.log(`[handleKeyDown] cleanMessage ${message} internalHint: ${internalHint.current.suggestion} matchedHint: ${matchedHint.suggestion}`);
            const cleanMessage = removeHint(message);
            setMessage(`${cleanMessage}${matchedHint.suggestion}`);
            internalHint.current = {
              source: message,
              suggestion: undefined
            };
            
            if (brandIconRef.current) {
              brandIconRef.current.blink();
            }
            
            onChange(cleanMessage);

          }
        }
        
    };
    
    // Hint must be inserted always (if enabled), because every render causes the MessageInput to be re-rendered, which will cause the hint to be loss
    useEffect( () => {
  
      if ( suggestionsEnabled === true ) {
        const [matchedHint] = matchHint(message, internalHint.current);
        insertHint(matchedHint);
      }
    });
    
    // Conversation changed
    // Set current message to a draft value
    useEffect(() => {
        if ( activeConversation?.id !== previousConversation?.id ) {
            
            // Reset message input when conversation changed
            const messageContent = currentMessage;             
            setMessage(messageContent);

            if ( suggestionsEnabled === true ) {
              
              // Remove hint (for now its too complicated to maintain hints for every conversation
              const newHint = {
                source: cleanMsg(messageContent),
                suggestion: "" // Empty string cause to remove hint from input
              };

              insertHint(newHint);
              internalHint.current = newHint;
              
            }
            
        } 
    },[previousConversation, activeConversation, suggestionsEnabled, currentMessage]);

    // Focus effect
    // activeSetBy click - set focus 
    // activeSetBy touchstart not set focus to prevent show keyboard on mobile
    // set focus only if page is not animating because setting focus breaks animation
    // TODO: Ale to trzeba poprawić, bo działa to jak w messengerze, czyli
    // jezeli drugi raz nacianę na usera to hook już nie zadziała, w skype jest tak samo
    // W telegramie za to jest to fajniejsze, bo jak mam zaznaczonego usera to gdziekowiek nie klknę to fosu jest ustaweioney
    useEffect(() => {
      
      if (activeSetBy === "click" || typeof activeSetBy === "undefined") {
        if ( ((previousAnimating === true || typeof previousAnimating === "undefined") && animating === false) // Active conversation changed in responsive view with animation
          || (previousAnimating === false && animating === false) // Active conversation changed in non-responsive view without animation  
        ) {
          inputRef.current?.focus()
        }
      }
      
    }, [animating, previousAnimating, activeSetBy, activeConversation]);
    
    const handleBeforeInput = evt => {
      
      if ( suggestionsEnabled === true ) {
        
        console.log("[handleBeforeInput]", evt);
        const msg = `${message}${evt.data}`;
        console.log(`[handleBeforeInput] msg: ${msg}`);
        //setMessage(cleanMessage);
        //setMessage(msg);
        const [newHint, full, matchCnt] = matchHint(msg, internalHint.current);
        console.log(`[handleBeforeInput] New hint source: '${newHint.source}', suggestion: '${newHint.suggestion}', full: ${full}, matchCnt: ${matchCnt}`);
        if (newHint.suggestion?.length > 0 && full === false) {
          console.log(`[handleBeforeInput] Inserting new hint ${newHint.suggestion}`);
          if (newHint.suggestion !== internalHint.current.suggestion) {
            insertHint(newHint);
            internalHint.current = newHint;
          } else { // Przypadek kiedy wpisuję literę nie pasującą do hinta - wtedy musimy hinta usunąć
            const newHint = {
              source: cleanMsg(removeHint(msg)),
              suggestion: ""
            };
            insertHint(newHint);
            internalHint.current = newHint;
          }

        } else if (full === true) {

          const newHint = {
            source: cleanMsg(removeHint(msg)),
            suggestion: "" // Empty string cause to remove hint from input
          };

          insertHint(newHint);
          internalHint.current = newHint;

        }
        
      }
    };
    
    const handleChange = msg => {
        console.log("[handleChange] msg: " + msg);
        
        const cleanMessage = removeHint(msg);
        console.log("[handleChange] cleanMessage: " + cleanMessage);
        setMessage(msg);
        
        onChange(cleanMessage);
    };
    
    const handleSend = value => {
             
        setMessage("");
        
        onSend(value);
        
        inputRef.current.focus();
        
    };
    
    const handleConversationChange = (evt, c) => {
        
        onConversationChange(evt, c);
        
    };
         
    const typingIndicator  = useTypingIndicator(activeConversation?.id, (typingUser) => `${typingUser.username} is typing` );    
    
    return (<MainContainer className="chat-main-container" responsive={true}>
                <Sidebar position="left" scrollable={false}>
                  
                    <ConversationListHeader user={user} onLogoutClick={onLogout} />
                    
                    <ConversationsView conversations={conversations} activeConversationId={activeConversation?.id}
                                       user={user}
                                       onConversationChange={handleConversationChange}
                                       showActive={hideFakeChatContainer === false} />
                    
                </Sidebar>
        {activeConversation && <ChatContainer>                 
                <CurrentConversationHeader as={ConversationHeader} onBackClick={onBack} />
                     
                <MessageList typingIndicator={typingIndicator && <TypingIndicator {...typingIndicator} />} scrollBehavior="auto"
                             autoScrollToBottomOnMount={activeConversation?.id !== "help-1"}
                             autoScrollToBottom={activeConversation?.id !== "help-1"}
                             className={classNames("chat-message-list", {"chat-message-list--h-100": activeConversation?.id === "creatext-presentation" || activeConversation?.id === "help-1"})}>
                  
                  {activeConversation?.id === "creatext-presentation" && <MessageList.Content className="w-100 h-100 pb-4">
                    <CreatextPresentation />  
                  </MessageList.Content>}
                    {activeConversation?.id === "help-1" && <MessageList.Content className="w-100 h-100 pb-4">
                        <HelpConversation />
                    </MessageList.Content>}
                  
                  {activeConversation?.id !== "creatext-presentation" && activeConversation !== "help-1" && messages.map( g =>
                    <MessageGroup key={g.id} direction={g.direction}>
                      <MessageGroup.Messages>
                        {g.messages
                          .map( m => {
                             if ( m.reward === true ) {
                               return <ConfettiMessage as={Message} key={m.id} message={m} dir={g.direction === "incoming"  ? "right" : "left"}/>;
                             } else {
                               return  <Message key={m.id} model={{
                                 type: getMessageType(m.contentType),
                                 payload: m.content
                               }}/>;
                             }
                          })}
                      </MessageGroup.Messages>
                    </MessageGroup>)}
                    
                </MessageList>

          {activeConversation?.id !== "creatext-presentation" && activeConversation?.id !== "help-1" && <div as={MessageInput} className="d-flex flex-row align-items-end chat-message-input-wrapper">
            <MessageInput ref={inputRef} placeholder="Type message here"
                          fancyScroll={true}
                          sendButton={true}
                          className="chat-message-input"
                          attachButton={false} onSend={handleSend}  onChange={handleChange} value={message}
                          disabled={!activeConversation || activeConversation?.readonly}
                          onClick={() => inputRef.current.focus()}
                          onKeyDown={handleKeyDown}
                          onBeforeInput={handleBeforeInput} />

              {/* Uncomment for branding */}
              {/*<CreatextBrandIcon ref={brandIconRef} />*/}
              {/*<SendButton onClick={() => handleSend(message) } disabled={message.length === 0}/>*/}
              
          </div>}
                        
            </ChatContainer>}
            
            {typeof activeConversation === "undefined" && hideFakeChatContainer === false &&
            <FakeChatContainer>
              {activeConversation?.id !== "creatext-presentation" && activeConversation?.id !== "help-1" && <Welcome usersLogged={conversations.length > 0}
                         currentUserDisconnected={currentUserDisconnected}
                         onBackClick={onBack} />}
            </FakeChatContainer>}
            
        </MainContainer>);
};

Chat.propTypes =  {
    user: userType,
    /**
     * Grouped messages
     */
    messages: PropTypes.array,
    conversations: PropTypes.array,
    users: PropTypes.array,
    activeSetBy: PropTypes.string,
    hint: PropTypes.shape({
        source: PropTypes.string,
        suggestion: PropTypes.string
    }),
    suggestionsEnabled: PropTypes.bool, 
    hideFakeChatContainer: PropTypes.bool,
    currentUserDisconnected: PropTypes.bool,
    onSend: PropTypes.func,
    onConversationChange: PropTypes.func,
    activeConversation: PropTypes.object,
    onLogout: PropTypes.func,
    onChange: PropTypes.func,
};

Chat.defaultProps = {
    messages: {},
    conversations: [],
    hint: {
        source:"",
        suggestion: undefined
    },
    suggestionsEnabled:false,
    hideFakeChatContainer: false,
    currentUserDisconnected: false,   
    onSend: () => noop,
    onConversationChange: noop,
    onBack: noop,
    onLogout: noop,
    onChange: noop
};

export default Chat;
