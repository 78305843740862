import {useEffect, useState} from "react";

/**
 * @param action Function with one boolean parameter - will be invoked on accept or on dismiss. This function must return function
 * @returns {{handleInstallClick: handleInstallClick, handleDismissClick: handleDismissClick}}
 */
export const useA2HS = (action) => {
    
    const [ prompt, setPrompt] = useState();
    
    useEffect( () => {

        const onDomContentLoaded = () => {

            // Check display mode and hide install prompt when application is launched not from browser
            if (navigator.standalone) {               
                setPrompt(false);
                action(false);
            }
            if (window.matchMedia('(display-mode: standalone)').matches) {
                setPrompt(false);
                setPrompt(false);
                action(false);
            }

        };

        const onBeforeInstallPrompt = (e) => {
            // Prevent Chrome 67 and earlier from automatically showing the prompt
            e.preventDefault();

            action(true);
            setPrompt(e);

        };
        
        const onAppInstaled = (evt) => action(false);
        
        window.addEventListener('DOMContentLoaded', onDomContentLoaded ); 

        window.addEventListener('beforeinstallprompt', onBeforeInstallPrompt ); 

        window.addEventListener('appinstalled', onAppInstaled );
        
        // Cleanup
        return () => {
            window.removeEventListener('DOMContentLoaded', onDomContentLoaded);
            window.removeEventListener('beforeinstallprompt', onBeforeInstallPrompt);
            window.removeEventListener('appinstalled', onAppInstaled);
        }
        
    },[action]);

    
    const handleInstallClick = () => {

        if ( prompt ) {
            prompt.prompt();
            // Wait for the user to respond to the prompt
            prompt.userChoice.then((choiceResult) => {
                
                /*if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                } else {
                    console.log('User dismissed the A2HS prompt');
                }*/
                
                setPrompt(false);
                action(false);

            }).catch(err => {
                console.error(err);
            });
        }

    };

    const handleDismissClick = () => {
        setPrompt(false);
        action(false);
    };
    
    
    return {
        handleInstallClick,
        handleDismissClick
    }
    
};