import {ActionTypes} from "../actions/chatservice.actions";

export const reducer = (state, action) => {

  if (typeof  state === "undefined") {
    return {
      initialized: false
    }
  }

  switch (action.type) {

    case ActionTypes.SetInitialized:
      return {
        ...state,
        initialized: action.payload
      };
      
    default:
      return state;

  }

};
