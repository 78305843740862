

export const FAQ = () => {
    
    return (<div className="chat-faq">
        <h2 className="mt-4 mb-3">FAQ</h2>
        <dl>
            <dt>Q: What is it?</dt>
            <dd>A: This is a demo of a chat application built with <a href="https://github.com/chatscope/chat-ui-kit-react" target="_blank" rel="noopener noreferrer">https://github.com/chatscope/chat-ui-kit-react</a> components.</dd>

            <dt>Q: What technologies were used to build this demo?</dt>
            <dd>A: The demo is built using the <a href="https://create-react-app.dev/" target="_blank" rel="noopener noreferrer"><abbr title="Create React App">CRA</abbr></a>.<br />
                The main libraries that have been used are: <a href="https://github.com/chatscope/use-chat" target="_blank" rel="noopener noreferrer">@chatscope/use-chat</a> with a dedicated adapter, <a href="https://github.com/chatscope/chat-ui-kit-react" target="_blank" rel="noopener noreferrer">@chatscope/chat-ui-kit-react</a> and <a href="https://getbootstrap.com/" target="_blank" rel="noopener noreferrer">Bootstrap</a>.
            </dd>

            <dt>Q: What about the backend?</dt>
            <dd>A: The chat server is a custom solution written in the <a href="https://www.scala-lang.org/" target="_blank" rel="noopener noreferrer">Scala Programming Language</a>.
                The messaging protocol is based on <a href="https://www.jsonrpc.org/specification" target="_blank" rel="noopener noreferrer">JSON-RPC 2.0</a>.</dd>

            <dt>Q: Is the source code for this demo publicly available?</dt>
            <dd>A: No. The application is based on unpublished packages responsible for the communication mechanism. However, it will likely be published in the future when the communication protocols are tidied up.</dd>

            <dt>Q: Is there any other demo I can see sources for?</dt>
            <dd>A: Currently there is one: <a href="https://github.com/chatscope/use-chat-example" target="_blank" rel="noopener noreferrer">@chatscope/use-chat-example</a>,
                but I'm working on another, more extensive application.</dd>
            <dt>Q: Is this demo being developed?</dt>
            <dd>A: Yes! I invite you here again. New functionalities will be added from time to time.</dd>

            <dt>Q: How can I get help with the components or use-chat library?</dt>
            <dd>A: You can report an <a href="https://github.com/chatscope/chat-ui-kit-react/issues" target="_blank" rel="noopener noreferrer">issue</a> or start a <a href="https://github.com/chatscope/chat-ui-kit-react/discussions" target="_blank" rel="noopener noreferrer">discussion</a> on GitHub.
                Or you can contact me directly: @see Contact and Resources above.</dd>
            <dt>Q: I like this app, can you make a similar one for me?</dt>
            <dd>A: Yes, of course! Please contact me to discuss the details.</dd>
            <dt>Q: How can I support this project?</dt>
            <dd>A: There are many options. You can give a star on GitHub, send a link to your friends, write about the project on Twitter, or make a PR to the repository.</dd>
        </dl>
    </div>);
}