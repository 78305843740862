import {Avatar} from "@chatscope/chat-ui-kit-react";
import pAvatar from "../../assets/pAvatar.png";

export const AboutAuthor = ({className}:{className?:string}) => {
    return (<div className={className}>
        <h2 className="mt-4 mb-3">About the author</h2>
        <div>
            <Avatar src={pAvatar} className="float-left mr-3 mb-1"/>
            <p>My name is Peter and I come from Poland - a beautiful country with mountains, sea and the four seasons of the year.<br />
            I'm a full-stack web developer experienced in communication applications.
            I'm also a big fan of the Scala Programming Language.<br />
                In my spare time, I love to ride an enduro bike, take photos and play the guitar.</p>
        </div>
    </div>);
}