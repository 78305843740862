import React from 'react';
import ReactDOM from 'react-dom';
import "./index.scss";
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
/*@ts-ignore */
import { Provider } from "react-redux";
import { ConnectedRouter } from 'connected-react-router'
import { ServicesProvider } from "./context-providers/ServicesProvider";

//import reportWebVitals from './reportWebVitals';
import configureStore, {history} from "./configureStore";
import ChatServiceStrategy from "./services/ChatServiceStrategy";
//import ConfigService from "./services/ConfigService";
import CreatextService from "./services/CreatextService";
import { nanoid } from "nanoid";
import { ShowNewAppVersionNotification } from "./redux/actions/app.actions";
import {BasicStorage, ChatProvider, AutoDraft, IStorage, } from "@chatscope/use-chat";
import type {ChatMessage, MessageContentType} from "@chatscope/use-chat"
import {Provider as ConfigProvider} from "./init";

const groupIdGenerator = () => nanoid();

const messageIdGenerator = <T extends MessageContentType>(message: ChatMessage<T>) => nanoid();

const serviceFactory = (storage: IStorage, updateState: () => void) => {
    return new ChatServiceStrategy(storage, updateState);
};

const externalStorage = new BasicStorage({ groupIdGenerator, messageIdGenerator});

if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
}

const store = configureStore({});

/*@ts-ignore */
const services = store => 
  ({
    //config: ConfigService(store),
      /*@ts-ignore */
    creatext: CreatextService({
      username: "",
      password: "",
      server: ""
    })
  });

// Strict mode causes double run of the router actions sometimes
// It happens during development only, but it can lead to strange effects
// https://github.com/supasate/connected-react-router/issues/193
/*ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <ConnectedRouter history={history}>
              <ServicesProvider services={services}>
                <App />
              </ServicesProvider>
          </ConnectedRouter>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);*/


ReactDOM.render(
    <Provider store={store}>
      <ConfigProvider configPath="config.js" timeout={3000}>
        <ConnectedRouter history={history}>
          <ServicesProvider services={services}>
            <ChatProvider serviceFactory={serviceFactory} storage={externalStorage} config={{
              typingThrottleTime: 250,
              typingDebounceTime: 900,
              debounceTyping: true,
              autoDraft: AutoDraft.Save | AutoDraft.Restore
            }}>
                <App />
            </ChatProvider>
          </ServicesProvider>
        </ConnectedRouter>
      </ConfigProvider>
    </Provider>,
  document.getElementById('root')
);

// TODO: Hook
/*@ts-ignore */
let waitingWorker;

const updateServiceWorker = () => {

    /*@ts-ignore */
  waitingWorker && waitingWorker.postMessage({ type: "SKIP_WAITING" });
  // A czy na pewno reload można zrobić? Czy tamto się nie wykona asynchronicznie?
  // Czy reload nie powinien być na callbacku?
  store.dispatch(ShowNewAppVersionNotification({show: false, onConfirm: undefined}));
  window.location.reload();
};

const onServiceWorkerUpdate = (registration:any) => {
  
  waitingWorker = registration && registration.waiting;
  
  store.dispatch(ShowNewAppVersionNotification({show: true, onConfirm: updateServiceWorker}));
  
    
};

// ????
const onSuccess = (registration: any) => {
  console.log("ON SUCCESS", onSuccess(""));
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register({
  onUpdate: onServiceWorkerUpdate,
  onSuccess
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
