import PropTypes from 'prop-types';
import {ArrowButton } from "@chatscope/chat-ui-kit-react";
import noop from "../../utils/noop";
import { useMediaQuery } from "../../hooks";
import {smallScreenMQ} from "../../utils/responsive";

export const Welcome = ({usersLogged, currentUserDisconnected, onBackClick}) => {
  
  // Show back button only when we are on small screen
  const showBackButton = useMediaQuery(smallScreenMQ);
  
 return (
  <div className="d-flex flex-column align-items-center justify-content-center flex-grow-1">
      {currentUserDisconnected && <div className="text-center">User disconnected</div>}
      {usersLogged && <div className="text-center">Select a user to start a conversation</div>}
      {usersLogged === false && <div className="text-center">Wait for other users to connect<br /> or<br /> login as different user in next browser tab</div>}
      {showBackButton && <ArrowButton direction="left" border onClick={onBackClick} className="mt-3">Go back to contact list</ArrowButton>}
  </div>
 );
 
};

Welcome.propTypes = {
    usersLogged: PropTypes.bool,
    currentUserDisconnected: PropTypes.bool,
    onBackClick: PropTypes.func
};

Welcome.defaultProps = {
    usersLogged: false,
    currentUserDisconnected: false,
    onBackClick: noop
};

export default Welcome;