import {useMemo} from "react";
import {Subject} from "rxjs";
import {filter, map} from "rxjs/operators";
import {cleanMsg} from "../utils/sanitize";
import {matchHint} from "../utils/hints";

export const useHintFilterObservable = () => useMemo(() => {

  const hintFilterSubject = new Subject();
  
  const hintFilterObservable = hintFilterSubject.pipe(
    map( ({msg, hint, recipientName}) => {       
        return {
          msg: cleanMsg(msg), hint, recipientName
        }
      }
    ),
    filter( ({msg, hint}) => { // Tylko jeżeli wpisana wiadomość, nie pasuje w ogóle to aktualnego hinta
      
      const match = matchHint(msg, hint);
      return match[2] === 0;
    }),
    map( ({msg, hint, recipientName}) => {
      return {
        source: msg,
        suggestion: "",
        recipientName
      }
    }),
  );
 
  return [hintFilterSubject, hintFilterObservable];
  
},[]);