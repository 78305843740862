export const removeHint = msg => msg.replace(/<span\sdata-hint.*<\/span>/g,"");

/**
 * Return three elements array:
 * 1. String new hint value,
 * 2. Boolean - if full hint matched message
 * 3. Match index - 0 means hint not match at all
 * @param String message
 * @param {Object} hint Hint object
 * @param {String} hint.source
 * @param {String} hint.suggestion
 * @returns Array
 */
export const matchHint = (message, { source, suggestion } ) => {

    console.log(`[matchHInt] Message '${message}'`);
    const cleanMessage = removeHint(message.replace("&nbsp;", " "));
    console.log(`[matchHInt] Clean message '${cleanMessage}', source: '${source}' hint: '${suggestion}'`);

    if ( typeof suggestion === "string") {
        
        let newHint = {source, suggestion};
        let i,
            lastMatch = 0;
        for (i = 1; i < suggestion.length+1; i++) {
            const hintPart = suggestion.slice(0, i);

            console.log(`[matchHInt] Matching ${hintPart} - ${cleanMessage.slice(-i, cleanMessage.length)}`);

            if (hintPart === cleanMessage.slice(-i, cleanMessage.length)) {
                console.log(`[matchHInt] MATCH ${i} newSource: '${cleanMessage.slice(0,-i+1)}'`);
                newHint = {
                  source: cleanMessage.slice(0,-i),
                  suggestion: suggestion.slice(i)
                };
                lastMatch = i;
            } else {
                console.log("[matchHInt] NOT MATCH");
            }
        }
        console.log(`i: ${i}, hint len: ${suggestion.length},  lastMatch: ${lastMatch}`);

        // Hint cant be matched with message, which is different from source
        // bacause hint is connected to source
        /*if ( cleanMessage !== source ) {
            return [{ source, suggestion}, false, 0];
        }*/
        
        return [newHint, lastMatch === i-1, lastMatch];
        
    } else {
        return [{ source, suggestion}, false, 0];
    }
};
