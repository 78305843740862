import { actionCreator } from "./actionCreator";

const MESSAGE_ACTION = "Chat/";

export const ActionTypes = {
  AddMessage : MESSAGE_ACTION + "AddMessage",
  SendMessage: MESSAGE_ACTION + "SendMessage",
  UpdateMessages: MESSAGE_ACTION + "UpdateMessages",
  ResetState: MESSAGE_ACTION + "ResetState",
};

export const AddMessage = actionCreator(ActionTypes.AddMessage);
export const SendMessage = actionCreator(ActionTypes.SendMessage);
export const UpdateMessages = actionCreator(ActionTypes.UpdateMessages);
export const ResetState = actionCreator(ActionTypes.ResetState);