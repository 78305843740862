import { actionCreator } from "./actionCreator";

const CHAT_SERVICE_ACTION = "ChatService/";

export const ActionTypes = {
  SetInitialized: CHAT_SERVICE_ACTION + "SetInitialized"
};

export const SetInitialized = actionCreator(ActionTypes.SetInitialized);

