import {useChat} from "@chatscope/use-chat";
import {Config, useConfig} from "../init";
import ChatServiceStrategy, {CommonChatService} from "../services/ChatServiceStrategy";
import {useEffect, useMemo, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {SetInitialized} from "../redux/actions/chatservice.actions";

/**
 * Application logic.
 * This hook is not reusable.
 * Use this hook only in one place in the app code.
 */
export const useInitializeChatService = () => {

  const { config } = useConfig();
  const {service} = useChat();

  const dispatch = useDispatch();
  
  // Initialized comes from the global state, as it does not make sense to create a context provider for just one flag
  /* @ts-ignore */
  const initialized = useSelector( state => state.chatService.initialized );
  
  // TODO: czy tutaj api nie powinno być jako referencja?
  const [api,setApi] = useState<CommonChatService>();
  
  // Need to cast because useChat is not  (yet) generic
  const chatService = useMemo( () => service as ChatServiceStrategy,[service]);
  
  useEffect( () => {
    console.log("useInitialize. Odpalam efekt");
    // A może prepare powino być po porstu callbackiem?
    const prepare = async (config: Config) => {

      return await chatService.init({

        adapterFactory: async (storage, updateState) => {

          if (config.adapter.name === "simple-chat") {

            const SimpleChatAdapter = await import( /* webpackChunkName: "simpleChatAdapter" */ "@chatscope/simple-chat-adapter" ).then(({SimpleChatAdapter}) => SimpleChatAdapter);

            // Teraz tutaj mam instancję adaptera, która powinna mieć przepisane wszystkie potrzebne rzeczy
            // Z głównego serwisu
            const adapter = new SimpleChatAdapter(storage, updateState);

            return adapter.init({
              server: config.adapter.options.server,
              api: config.adapter.options.api
            });

          } else { // Domyślnie ładuję adapter głównego ChatService

            const ChatServiceAdapter = await import( /* webpackChunkName: "chatServiceAdapter" */"../services/ChatService").then(({default: adapter}) => adapter);

            /* @ts-ignore */
            const adapter = new ChatServiceAdapter(storage, updateState);
            return adapter.init({
                license: "test",
                server: config.adapter.options.server,
                useSharedWorkerWhenPossible: false,
                workerPath: "ChatProxyClientWorker.js"
              }
            );
            
          }

        }
      });
      
    }
    
    if (config && !initialized) {
      prepare(config).then( api => {
        setApi(api);
        dispatch(SetInitialized(true));
      });
    }
  }, [config, chatService, setApi, dispatch, initialized]);

  return {
    chatService: api
  }

}
