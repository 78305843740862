import {ChatEventType, IStorage} from "@chatscope/use-chat";
import type { IChatService,SendMessageServiceParams,SendTypingServiceParams} from "@chatscope/use-chat";
import {BaseChatAdapter} from "@chatscope/simple-chat-adapter/dist/BaseChatAdapter";

export interface CommonChatService extends IChatService {
  register: (...params:any) => Promise<any>;
  getContacts: () => Promise<any>;
  sendRequest: (...params:any) => any;
  disconnect: () => void;
}

interface InitParams {
  adapterFactory: (storage: IStorage, updateState: () => void) => Promise<CommonChatService>;
}

class ChatServiceStrategy extends BaseChatAdapter {
  
  public service?: CommonChatService;
  
    init (p:InitParams):Promise<CommonChatService> {
        return new Promise(async (resolve, reject) => {
          
          if ( this.storage ) {
            this.service = await p.adapterFactory(this.storage, this.updateState);
            
            this.service.on(ChatEventType.Message, this.eventHandlers.onMessage);
            // TODO: Is it necessarry?
            //service.on("userStateChanged", callbacks.onUserStateChanged);
            /* @ts-ignore */
            this.service.on("userStateChanged", () => {
              console.error("Received userStateChanged I don't know what is it!!!! Fix it Bro!");
            });
            this.service.on(ChatEventType.UserTyping, this.eventHandlers.onUserTyping);
            //service.on("onRemoveConversation", callbacks.onRemoveConversation);
            resolve(this.service);
          } else {
            reject("Storage not set")
          }
          
        });

      }

    register(user:string, password:string ) {     
      return this.service?.register(user, password);
    }
     
    getContacts() {
      return this.service?.getContacts();
    }

    // Experimental
    // Low level api testing
    sendRequest (req:any) {
      return this.service?.sendRequest(req);
    }
    
    sendMessage ( {message, conversationId}: SendMessageServiceParams) {     
      return this.service?.sendMessage({message, conversationId});       
    }

    /**
     * IChatService
     * @param {Object} params Parameters object
     * @param {String} params.userId
     * @param {String} params.conferenceId
     * @param {String} params.content
     */
    sendTyping (params:SendTypingServiceParams) {
        return this.service?.sendTyping(params);
    }
    
    disconnect() {
      this.service?.disconnect();
    }
    
}

export default ChatServiceStrategy;