import { actionCreator } from "./actionCreator";

const CONVERSATION_ACTION = "Conversation/";

export const ActionTypes = {
  AddConversation : CONVERSATION_ACTION + "AddConversation",
  RemoveConversation: CONVERSATION_ACTION + "RemoveConversation",
  SetActive: CONVERSATION_ACTION + "SetActive",
  SetActiveSetBy: CONVERSATION_ACTION + "SetActiveSetBy",
  SetCurrentMessage: CONVERSATION_ACTION + "SetCurrentMessage",
  SetTypingUser: CONVERSATION_ACTION + "SetTypingUser",
  SetUnreadCounter: CONVERSATION_ACTION + "SetUnreadCounter",
  SetCurrentUserDisconnected: CONVERSATION_ACTION + "SetCurrentUserDisconnected",
  ResetState: CONVERSATION_ACTION + "ResetState"
};

export const AddConversation = actionCreator(ActionTypes.AddConversation);
export const RemoveConversation = actionCreator(ActionTypes.RemoveConversation);
export const SetActive = actionCreator(ActionTypes.SetActive);
export const SetActiveSetBy = actionCreator(ActionTypes.SetActiveSetBy);
export const SetCurrentMessage = actionCreator(ActionTypes.SetCurrentMessage);
export const SetTypingUser = actionCreator(ActionTypes.SetTypingUser);
export const SetUnreadCounter = actionCreator(ActionTypes.SetUnreadCounter);
export const SetCurrentUserDisconnected = actionCreator(ActionTypes.SetCurrentUserDisconnected);
export const ResetState = actionCreator(ActionTypes.ResetState);
  


