import immutableObject from "../helpers/immutableObject";
import axios from "axios";

/**
 * 
 * @param Object config Configuration object
 * @param String config.username Username
 * @param String config.password Password
 * @param String config.server
 * @returns {CreatextService}
 * @constructor
 */
function CreatextService( {username, password, server}) {
  
  const ret = immutableObject(null,{
    
    getHint: {
      /**
       * Gets hint from server
       * @returns {Promise<Object>}}
       */
      value: async (msg, recipientName) =>  {

        console.log(`%cSENDING: ${msg}`, 'color: green');

        const res = await axios.post(server,
          {
            "text": msg,
            "recipient_first_name": recipientName,
            "languages": [
              "en"
            ],
            "tones": [
              "formal",
              "informal"
            ]
          },
          {
            auth: {
              username: username,
              password: password
            }
          });
        console.log("[ChatContainer] Response", res);

        if (res.data.length > 0) {
          const hint = {
            source: res.data[0].source_str,
            suggestion: res.data[0].suggestion
          };

          console.log("[ChatContainer] Setting hint to", hint);

          return hint;

        } else {
          return {
            source: msg,
            suggestion: undefined
          };
        }

      }
    }
    
  });
  
  return ret;
}

export default CreatextService;