import { useEffect, useRef } from "react";
import * as typeformEmbed from "@typeform/embed";

export const Presentation = () => {

  const typeformRef = useRef();
  
  useEffect(() => {
    
    typeformEmbed.makeWidget(typeformRef.current, "https://form.typeform.com/to/M7k1YCwS",{
      hideHeaders: true,
      hideFooter: true
    });
         
  },[typeformRef]);
  
    return (
      <div className="d-flex align-items-center justify-content-center h-100">
        <div ref={typeformRef} className="w-100 h-100"></div>
      </div>
    );
};

export default Presentation;