import { Suspense } from "react";
import {Loader} from "@chatscope/chat-ui-kit-react";

const Loading = () => <div className="w-100 h-100 d-flex align-items-center justify-content-center"><Loader /></div>;
    
export const LoadingFallback = ({children}) => {
    
    return <Suspense fallback={<Loading/>}>{children}</Suspense>
    
};

export default LoadingFallback;
