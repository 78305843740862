import {lazy, useState} from "react";
import { useLocation, Switch } from "react-router-dom";
import { AppRoute, LoginRoute } from "../routes";
import { LoadingFallback } from "../components/";
import { usePrevious } from "../hooks";
import {useSelector} from "react-redux";
import { useTransition, animated } from 'react-spring'
import { easeBackOut } from "d3-ease";
import { useChat} from "@chatscope/use-chat";

const ConversationsPage = lazy(() =>  import("../pages/ConversationsPage") );
const ChatPage = lazy( () => import("../pages/ChatPage"));
const ContactPage = lazy( () => import("../pages/ContactPage"));

const RoutesContainer = () => {
  
  const [animating, setAnimating] = useState(true);
  const location = useLocation();
  const previousPathname = usePrevious(location.pathname);
  const loggedIn = useSelector( state => state.auth.loggedIn );
  
  const { setActiveConversation } = useChat();
  
  const dir = (() => {
    const { pathname } = location;
    
    if  ( pathname === "/" || pathname === "/login" ) {
        return "none";
    } else if  (previousPathname === "/login" && pathname === "/conversations" ) {
        return "none"
    } else if ( previousPathname === "/conversations" && pathname === "/chat" ) {
      return "l";
    } else if ( previousPathname === "/chat" && pathname === "/conversations" ) {
      return "r"
    } else {
      
      return "none"
    }
  })();
  
  const loItem = {...location, dir};
  
  const transitions = useTransition(loItem, location => location.pathname, {
    config: {
      clamp: true,
      mass: 1,
      tension: 150,
      friction:1,
      easing: easeBackOut
    },

    onStart: (item,state) => {
      if ( item.pathname !== "/conversations" && state === "enter" ) {
        setAnimating(true);
      }
    },
    
    onRest: (item,state) => {
      if ( item.pathname === "/chat" && state === "update" ) {
        setAnimating(false);
      }
      
      if( item.pathname === "/conversations" && state === "update" ) {
        // Clean active after entering to conversation page
        setActiveConversation({conversationId: undefined, setBy: undefined});
      }
      
    },
    
    from: item => ({
      width:"100%",
     offset: (() => {
       if ( item.dir === "none" ) {
         return 0;
       }

       if ( item.pathname === "/conversations" ) {
         if ( item.dir === "r" ) {
           return -100;
         }
       } else {
         if ( item.dir === "l" ) {
           return 100;
         } 
       }})(),
      opacity: 0
    }),
    enter: (item) => {
      return {
        offset: item.pathname === "/conversations" ? 0 : 0,
        opacity: 1
      }
    },
    leave: item => ({
      offset: item.pathname === "/conversations" ? -100 : 100,
      opacity: 0
    }),
  });
  
    return (<LoadingFallback>
        {loggedIn === false && <LoginRoute exact path="/login" />}
        {loggedIn === true && <>
            {transitions.map(({ item, props, key, state }) => {
              return <animated.div key={key} style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                transform: props.offset.interpolate( o => `translateX(${o}%)`),
                opacity: props.opacity
              }}>
                <Switch location={item}>
                  <AppRoute exact path="/chat" view={ChatPage}  animating={animating} />
                  <AppRoute exact path="/conversations" view={ConversationsPage} animating={animating}  />
                  <AppRoute exact path="/contact" view={ContactPage} animating={animating} />
                </Switch>
              </animated.div>})}
          </>}
  </LoadingFallback>);
    
};

export default RoutesContainer;