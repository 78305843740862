import {ActionTypes as UserActionTypes} from "../actions/user.actions";
import UserModel from "../../models/UserModel";

export const reducer = (state, action) => {

  if (typeof  state === "undefined") {

    return UserModel;

  }

  switch (action.type) {
    
    case UserActionTypes.SetUsername:

      return {...state, username: action.payload};

    case UserActionTypes.SetFirstName:

      return {...state, firstName: action.payload };

    case UserActionTypes.SetLastName:

      return {...state, lastName: action.payload };

    case UserActionTypes.SetStatus:

      return {...state, status: action.payload};
      
    case UserActionTypes.SetStatusDescription:
      
      return {...state, statusDescription: action.payload};
      
    case UserActionTypes.SetPermissions:

      return {...state, permissions: action.payload };

    case UserActionTypes.SetAvatar:
      
      return {...state, avatar: action.payload };
      
    case UserActionTypes.SetUserData:

      const { id, username, firstName, lastName, status, statusDescription, avatar, permissions } = action.payload;
      return {id, username, firstName, lastName, status, statusDescription, avatar, permissions };
      
    default:
      return state;

  }

};
