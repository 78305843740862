import {debounceTime, filter, mergeMap} from "rxjs/operators";
import {BehaviorSubject, from, Subject} from "rxjs";
import {toggle} from "../rxjs/operators";
import {useMemo} from "react";

export const useHintObservable = (creatextService) => useMemo( () => {

  const toggleHintSubject = new BehaviorSubject(true);
  const getHintSubject = new Subject();
  
  const getHintObservable = getHintSubject.pipe(
    filter(({source}) => {
      console.log("XX 4");
      return source.length > 0
    } ),
    debounceTime(150),
    mergeMap( ({source, recipientName}) => {
      console.log("XX 5");
      return from(creatextService.getHint(source, recipientName));
    }),
    filter( serverHint => {
      console.log("XX Filtruję serverHint", serverHint);
      console.log("XX 6");
      return serverHint.suggestion?.length > 0 ?? false
    }),
    toggle(toggleHintSubject)
  );

  return [toggleHintSubject, getHintSubject, getHintObservable];

},[creatextService]);