import {useMemo} from "react";
import PropTypes from "prop-types";
import creatextLogo from "./creatext-logo.png";

export const Creatext = ({className, size, ...rest}) => {
    
  const maxWidth = useMemo( () => {
    return `${{
      "sm": "100",
      "md": "100",
      "lg": "400",
      "xl": "800"
    }[size]}px`;
    
  },[size]); 
      
    return (
        <div className={className} {...rest}>
          <p className="text-muted d-flex flex-column flex-md-row align-items-md-center"><span>AI text suggestions provided by:</span>
            <a href="https://creatext.ai" target="_blank" rel="noopener noreferrer" className="mt-2 align-self-end mt-md-0 ml-md-2">
              <img src={creatextLogo} alt="Creatext logo" style={{maxWidth}}/>
            </a>
          </p>
        </div>
    );
};

Creatext.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(["sm", "md", "lg", "xl"])
};

Creatext.defaultProps = {
  size: "md"
};

export default Creatext;