import PropTypes from 'prop-types';
import {Button, Toast} from "react-bootstrap";
import React from "react";
import noop from "../../utils/noop";

export const NewAppVersionNotification = ({show, onClose, onInstallClick}) => {
    return (       
          <Toast show={show} onClose={onClose} className="chat-new-app-version-notification">
            <Toast.Body className="text-center">
              <p>A new version of the application is available.</p>
              <p>Are you ready for a new experience?</p>

              <Button type="button" variant="success" onClick={onInstallClick}>Yes! Upgrade now</Button>
              <br className="d-md-none"/>
              <Button type="button" variant="outline-danger" className="ml-0 ml-md-4 mt-2 mt-md-0" onClick={onClose}>No thanks</Button>
              
            </Toast.Body>
          </Toast>
    );
};

NewAppVersionNotification.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onInstallClick: PropTypes.func
};

NewAppVersionNotification.defaultProps = {
  show: false,
  onClose: noop,
  onInstallClick: noop
};

export default NewAppVersionNotification;