const UserModel = {
  id:"",
  username: "", // Login
  firstName: "",
  lastName: "",
  status: "",
  statusDescription: "",
  avatar: "",
  permissions: []
};

export default UserModel;
