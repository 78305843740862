import {Presence, User, UserStatus} from "@chatscope/use-chat";
import lillyAvatar from "../assets/lilly.svg";
import eliotAvatar from "../assets/eliot.svg";
import feedbackAvatar from "../assets/feedbackAvatar9.svg";
import alienAvatar from "../assets/alienAvatar.png";
import helpAvatar from "../assets/help.svg";

export const Lilly = new User({
    id: "lilly-1",
    avatar: lillyAvatar,
    username: "[Bot] Lilly",
    email: "lilly@chatscope.io",
    firstName: "Lilly",
    presence: new Presence({status: UserStatus.Available})
});

export const Elliot = new User({
    id: "eliot-1",
    avatar: eliotAvatar,
    username: "[Bot] Eliot",
    email: "eliot@chatscope.io",
    firstName: "Eliot",
    presence: new Presence({status: UserStatus.Away})
});

export const Feedback = new User({
    id:"feedback-1",
    avatar: feedbackAvatar,
    username: "[Bot] Leave feedback",
    presence: new Presence({status: UserStatus.Eager})
});

export const Martian = new User({
    id:"martian-1",
    avatar: alienAvatar,
    username: "Martian",
    presence: new Presence({status: UserStatus.Unavailable})
});

export const Help = new User({
    id:"help-1",
    avatar: helpAvatar,
    username: "Help",
    presence: new Presence({status: UserStatus.Unknown})
});