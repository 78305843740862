import {EMPTY, Subject} from "rxjs";
import {switchMap} from "rxjs/operators";

/**
 * Custom toggle operator for RxJS
 * Projects each source value to an Observable if toggleSubject emits true in other case emits empty 
 * @param toggleSubject
 * @returns {function(*): Subject<unknown>}
 */
export const toggle = (toggleSubject) => (mainSubject) => {

  const subject = new Subject();

  toggleSubject.pipe(
    switchMap((v) => v ? mainSubject : EMPTY )
  ).subscribe( v => {
    subject.next(v);
  });
  
  return subject;

};

export default toggle;