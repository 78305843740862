import {ActionTypes as AppActionTypes} from "../actions/app.actions";

export const reducer = (state, action) => {

  if (typeof  state === "undefined") {
    return {
      hideFakeChatContainer: false, // Visual effects: flag for hiding FakeChatContainer, and active chat on ConversationList during animation
      showNewAppVersionNotification: {
        onConfirm: undefined,
        show: false
      }
    }
  }

  switch (action.type) {

    case AppActionTypes.SetHideFakeChatContainer:
      return {
        ...state,
        hideFakeChatContainer: action.payload
      };

    case AppActionTypes.ShowNewAppVersionNotification: 
      return {
        ...state,
        showNewAppVersionNotification: action.payload
      };
      
    default:
      return state;

  }

};
