import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBook, faEnvelope, faGlobe} from "@fortawesome/free-solid-svg-icons";
import {faDiscord, faFacebookSquare, faGithub, faTwitter} from "@fortawesome/free-brands-svg-icons";

export const ContactAndResources = () => {
  
    return (<>
        <h2 className="mt-4 mb-3">Contact and resources</h2>

        <div className="mb-2">
            <FontAwesomeIcon icon={faGlobe} className="mr-2"/>
            <a href="https://chatscope.io" target="_blank" rel="noopener noreferrer">https://chatscope.io</a>
        </div>

        <div className="mb-2">
            <FontAwesomeIcon icon={faTwitter} className="mr-2"/> <a href="https://twitter.com/chatscope" target="_blank" rel="noopener noreferrer">@chatscope</a>
        </div>

        <div className="mb-2">
            <FontAwesomeIcon icon={faFacebookSquare} className="mr-2"/> <a href="https://www.facebook.com/chatscope" target="_blank" rel="noopener noreferrer">https://www.facebook.com/chatscope</a>
        </div>
        <div className="mb-2">
            <FontAwesomeIcon icon={faDiscord} className="mr-2"/> <a href="https://discord.gg/TkUYWQRf2M" target="_blank" rel="noopener noreferrer">@chatscope</a>
        </div>

        <div className="mb-2">
            <FontAwesomeIcon icon={faEnvelope} className="mr-2"/> <a href="mailto:chat@chatscope.io" target="_blank" rel="noopener noreferrer">chat@chatscope.io</a>
        </div>

        <div className="mb-2">
            <FontAwesomeIcon icon={faGithub} className="mr-2"/>
            <a href="https://github.com/chatscope/chat-ui-kit-react" target="_blank" rel="noopener noreferrer">https://github.com/chatscope/chat-ui-kit-react</a>
        </div>
        <div className="mb-2">
            <FontAwesomeIcon icon={faGithub} className="mr-2"/>
            <a href="https://github.com/chatscope/use-chat" target="_blank" rel="noopener noreferrer">https://github.com/chatscope/use-chat</a>
        </div>

        <div className="mb-2">
            <FontAwesomeIcon icon={faBook} className="mr-2"/> <a href="https://chatscope.io/storybook/react/" target="_blank" rel="noopener noreferrer">https://chatscope.io/storybook/</a>
        </div>
    </>);
    
};