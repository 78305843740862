import {ActionTypes as InstallBannerActionTypes } from "../actions/installBanner.actions";

export const reducer = (state, action) => {

  if (typeof  state === "undefined") {
    return {
      state: false
    }
  }

  switch (action.type) {
    
    case InstallBannerActionTypes.SetInstallBannerState:
      
      return {
        state: action.payload
      };
      
    default:
      return state;

  }

};
