import {batch, useDispatch} from "react-redux";
import {ResetState} from "../headless-chat/storage/redux/actions/conversation.actions";
import {SetInitialized} from "../redux/actions/chatservice.actions";
import {SetAuthState} from "../redux/actions/auth.actions";
import {push} from "connected-react-router";
import {useChat} from "@chatscope/use-chat";
import {useCallback} from "react";
import {CommonChatService} from "../services/ChatServiceStrategy";

/**
 * Application logic.
 * Performs all operations required to log off.
 */
export const useLogout = () => {
  
  const {resetState, service} = useChat();
  const dispatch = useDispatch();
  
  return useCallback(() => {
    batch(() => {
      // TODO: Tutaj trzeba do reducera dodać middleware, który zrobi clean wszystkiego
      // TODO: Do czego to jest, czy to jest teraz potrzebne - te dwa dispatche???
      (service as CommonChatService).disconnect();
      resetState();
      dispatch(ResetState());
      dispatch(SetInitialized(false));
      dispatch(SetAuthState(false));
      dispatch(push("/login"));
    });  
  },[dispatch, resetState, service]);
  
}