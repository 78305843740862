import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as chatServiceReducer } from "./chatservice.reducer";
import { reducer as authReducer } from "./auth.reducer";
import { reducer as userReducer } from "./user.reducer";
import { reducer as installBannerReducer } from "./installBanner.reducer";
import { reducer as appReducer } from "./app.reducer";
import { reducer as chatReducer } from "../../headless-chat/index";

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    chatService: chatServiceReducer, 
    auth: authReducer,
    user: userReducer,
    chat: chatReducer,
    installBanner: installBannerReducer,
    app: appReducer
});

export default createRootReducer;