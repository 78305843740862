import {useState, useRef, useEffect } from "react";
import type {ChangeEvent, KeyboardEvent, ReactElement} from "react";
import {Form, Card, Button, Row, Col, CardGroup, InputGroup, Alert } from "react-bootstrap";
import { Loader } from "@chatscope/chat-ui-kit-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock } from "@fortawesome/free-solid-svg-icons";
import {InvalidCredentialsMessage} from "./InvalidCredentialsMessage";

export interface LoginData {
    username: string;
    password: string;
}

interface LoginProps {
    validator?: (data:LoginData) => boolean;
    forgotPassword?: boolean;
    register?: boolean;
    onLoginClick?: (data:LoginData) => void;
    onForgotPasswordClick: () => void;
    onRegisterClick: () => void;
    onChange?: (evt:ChangeEvent<HTMLInputElement>, data: LoginData) => void;
    inputsDisabled?: boolean;
    loading?: boolean;
    labels?: boolean;
    branding: ReactElement;
    showPassword?: boolean;
    showInfo?: boolean;
    usernamePlaceholder?: string;
    passwordPlaceholder?: string;
    onUserIconClick?: () => void;
    invalidCredentials?: boolean;
}

export const Login = ({ validator,
                        forgotPassword = false,
                        register = false,
                        onLoginClick,
                        onForgotPasswordClick,
                        onRegisterClick,
                        onChange,
                        inputsDisabled = false,
                        loading = false,
                        labels = false,
                        branding,
                        showPassword = true,
                        showInfo = false,
                        usernamePlaceholder,
                        passwordPlaceholder,
                        onUserIconClick,
                        invalidCredentials = false
                      }:LoginProps) => {
    
    const usernameRef = useRef<HTMLInputElement>(null);
    const [data, setData ] = useState<LoginData>({username:"", password:""});
    const [valid, setValid] = useState(validator ? validator(data) : true);
    
    useEffect(  () => usernameRef.current?.focus(),[]);
    
    const handleInput = (evt:ChangeEvent<HTMLInputElement>) => {
        
        const newData = {...data, [evt.target.name]: evt.target.value};
        setData(newData);
        setValid(validator ? validator(newData) : true);
        if ( onChange ) {
            onChange(evt, newData);
        }
        
    };
    
    const handleKeyDown = (evt:KeyboardEvent<HTMLInputElement>) => {
        if ( onLoginClick && evt.key === "Enter" && !evt.shiftKey) {
            onLoginClick(data);
        }
    };
    
    const handleLoginClick = () => {
        setData({...data, password:""});
        if ( onLoginClick ) {
            onLoginClick(data);
        }
    };
    
    return (<>
      <Row className="chat-login">
        <Col>
          <CardGroup className="border-0">
            {/*
              <Card className="d-sm-none d-md-flex" style={{
                minWidth: "150px",
                backgroundImage: "url('P2130197.JPG')",
                backgroundAttachment: "fixed",
                backgroundSize: "cover"
              }}>
                <Card.Body className="text-white mt-5" style={{fontSize: "1.2em", backdropFilter: "blur(2px)"}}>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut egestas diam erat, eget commodo mauris
                    euismod eget. Maecenas lorem nisi, sollicitudin a sagittis vitae, eleifend ac tellus. Aliquam
                    aliquam in enim nec venenatis. Aenean tincidunt turpis tincidunt turpis interdum mollis. Morbi
                    varius lorem non dapibus blandit. Nulla non velit bibendum mauris finibus venenatis quis ut orci.
                    Nulla nec volutpat turpis, nec pulvinar dolor. Maecenas sollicitudin bibendum faucibus. Suspendisse
                    sagittis, magna id consectetur vulputate, nisl lacus convallis elit, vel pulvinar metus erat ut
                    purus.</p>
                </Card.Body>
              </Card>
            */}
            <Card className="border-0" >
              <Card.Body>    
                  <Form className="chat-login__form">
                      <h1 className="mb-2">Login to your account</h1>
                      
                      <div className="chat-login__ivalid-credentials mb-2">
                        {invalidCredentials && <InvalidCredentialsMessage />}
                      </div>
                      
                      {showInfo === true && <Alert variant="info">Please use your name as username.<br/>
                        You can enter anything in the password field</Alert>}
                      
                      <Form.Group>
                        {labels === true && <Form.Label>Username</Form.Label>}
                          <InputGroup>
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faUser} onClick={onUserIconClick}/>
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                          <Form.Control ref={usernameRef} name="username" type="text"
                                        placeholder={usernamePlaceholder}
                                        value={data.username}
                                        onInput={handleInput}
                                        onKeyDown={handleKeyDown} autoComplete="username"
                                        disabled={inputsDisabled} />
                          </InputGroup>
                      </Form.Group>
              
                    {showPassword ===true && <Form.Group>
                        {labels === true && <Form.Label>Password</Form.Label>}
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faLock} />
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                            <Form.Control name="password" type="password"
                                          placeholder={passwordPlaceholder}
                                          value={data.password}
                                          onInput={handleInput}
                                          onKeyDown={handleKeyDown} autoComplete="current-password"
                                          disabled={inputsDisabled} />
                        </InputGroup>
                      </Form.Group>}
                      
                      <Button size="sm" className="w-100 mt-2" onClick={handleLoginClick}
                              disabled={data.username.length === 0 || (showPassword === true && data.password.length === 0) || !valid}>Login</Button>
                    
                      {(forgotPassword || register) && <div className="mt-4 text-center">
                        
                        {forgotPassword && <div>
                              <span className="link chat-login__forgot-password" onClick={onForgotPasswordClick}>Forgot password?</span>
                          </div>}

                        {register && <div className="mt-2">
                          <span className="link chat-login__create-account" onClick={onRegisterClick}>Create new account</span>
                        </div>}
                          
                      </div>}
                      
                  </Form>
                {branding}
              </Card.Body>
            {loading && 
              <>
                <div className="w-100 h-100 position-absolute d-flex align-items-center justify-content-center bg-light" style={{zIndex: 10, opacity: "0.4"}} />
                <div className="w-100 h-100 position-absolute d-flex align-items-center justify-content-center" style={{zIndex: 11}}>
                  <Loader />
                </div>
              </>}
            </Card>
          </CardGroup>
        </Col>
      </Row>
    </>);
    
};

export default Login;