export abstract class BaseException<D> {
    readonly message: string;
    readonly data?: D;

    protected constructor(message:string, data?:D) {
        this.message = message;
        this.data = data;
    }

}

export class InvalidCredentialsException extends BaseException<undefined> {
    
    constructor(message?:string) {
        
        const tMessage = message ?? "Invalid credentials"; 
        super(tMessage);
    }
}
