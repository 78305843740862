import {useEffect, useRef} from "react";
import {Message} from "@chatscope/chat-ui-kit-react";
import {useChat} from "@chatscope/use-chat";
import { confetti } from "dom-confetti";

interface ConfettiMessageProps {
    message:any; // TODO: Fix data type
    dir: "left" | "right"
}

export const ConfettiMessage = ({message, dir}:ConfettiMessageProps) => {
  
    const { updateMessage } = useChat();
    
    const confettiRef = useRef<HTMLSpanElement>(null);
    
    useEffect(() => {
      if ( confettiRef.current ) {
       confetti(confettiRef.current,{
         angle: dir === "right" ? 70 : 120,
       }).then(() => {
         delete message.reward;
         updateMessage(message); 
       });        
      }
      
    },[confettiRef, updateMessage, dir, message]);

  return (
    <Message>
      <Message.CustomContent>
          <span ref={confettiRef} style={{height:0}}></span>
          <Message.HtmlContent html={message.content} />
      </Message.CustomContent>
    </Message>
  );
     
};


ConfettiMessage.defaultProps = {};

export default ConfettiMessage;