import {ActionTypes as AuthActionTypes} from "../actions/auth.actions";

export const reducer = (state, action) => {

  if (typeof  state === "undefined") {
    return {
      loggedIn: false
    }
  }

  switch (action.type) {
    
    case AuthActionTypes.SetAuthState:
      return {
        ...state,
        loggedIn: action.payload
      };
      
    default:
      return state;

  }

};
