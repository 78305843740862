import {Button, Modal} from "react-bootstrap";

interface NotAvailableModalProps {
    show?:boolean;
    onHide: () => void
}

export const NotAvailableModal = ({show = false, onHide}:NotAvailableModalProps) => <Modal show={show} onHide={onHide} centered>
    <Modal.Header closeButton>
        <Modal.Title className="text-info">Not available</Modal.Title>
    </Modal.Header>

    <Modal.Body>
        <p>We are sorry, but this feature is not available in the demo.</p>
    </Modal.Body>

    <Modal.Footer className="justify-content-center">
        <Button variant="primary" size="sm" className="px-4" onClick={onHide}>Close</Button>
    </Modal.Footer>

</Modal>;
    
export default NotAvailableModal;