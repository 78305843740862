import { actionCreator } from "./actionCreator";

const CHAT_ACTION = "Chat/";

export const ActionTypes = {
  AddMessage : CHAT_ACTION + "AddMessage",
  SendMessage: CHAT_ACTION + "SendMessage",
  UpdateMessages: CHAT_ACTION + "UpdateMessages"
};

export const SendMessage = actionCreator(ActionTypes.SendMessage);
export const UpdateMessages = actionCreator(ActionTypes.UpdateMessages);

// Add message action creator checks if its award-winning :)
const awardWinningWords = ["super", "congratulations", "happy birthday", "fantastic", "awesome"];

const matchWord = (str) => {
  return typeof awardWinningWords.find( s => {
    const rx = new RegExp(s);
    return rx.test(str);
  }) !== "undefined";
};

export const AddMessage = payload => {
  
  const newPayload = payload.message.reward === true ? payload : {...payload, message: { ...payload.message, reward: matchWord(payload.message.model.message) } };
  
  return {type: ActionTypes.AddMessage, payload:newPayload};
  
};