import {ActionTypes as MessagesActionTypes} from "../actions/message.actions";
import {nanoid} from "nanoid";
export const reducer = (st, action) => {
  
  const state = st?.messages;
  
  const defaultState = {};
  
  if (typeof  state === "undefined") {
    // Messages indexed by conversationId
    // Message groups indexed by conversationId
    // Group object:
    // { id: string,
    //   direction:string,
    //   senderId: string,
    //   messages: array
    // }
    // { _id_: [ Group ]
    // ]}
    return {...defaultState};
    
  }

  switch (action.type) {
    
    case MessagesActionTypes.AddMessage:
      
      const { payload: { conversationId, message} } = action;
      
      /**
       * @return [newGroup, groupId, messageId]
       */
      const newGroups = (() => {
        
        if ( conversationId in state ) {
          
          const groups = state[conversationId];
          const lastGroup = groups.slice(-1)[0];
          
          if ( lastGroup.senderId === message.senderId ) { // Add message to group
            
            const newMessages = lastGroup.messages.concat({...message, id: nanoid()});
            const newGroup = {...lastGroup, messages: newMessages};
            
            return groups.slice(0,-1).concat(newGroup);
            
          } else { // Create new group
            
            const newGroup = {
              id: nanoid(),
              direction: message.direction,
              senderId: message.senderId,
              messages: [{...message, id: nanoid()}]
            };
            
            return [...groups, newGroup];
            
          }
          
        } else {
          
          const group = {
            id: nanoid(),
            direction: message.direction,
            senderId: message.senderId,
            messages: [{...message, id: nanoid()}]
          };
          
          return [group];
          
        }
        
      })();
      
      
      return {
        ...state,
        [conversationId]: newGroups
      };
      
    case MessagesActionTypes.ResetState:
      return {...defaultState}; 
    
    default:
      return state;

  }

};
