import {useMemo} from "react";
import PropTypes from 'prop-types';
import classNames from "classnames";

export const Footer = ({className}) => {
  const year = useMemo( () => (new Date()).getFullYear(), []);
    return (
          <div className={classNames("text-center text-muted pb-3 mt-3 chat-footer", className)}>
            <a href="https://chatscope.io" target="_blank" rel="noopener noreferrer">chatscope.io</a> &copy; {year}
          </div>       
    );
};

Footer.propTypes = {
  className: PropTypes.string
};

Footer.defaultProps = {};

export default Footer;