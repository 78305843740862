import {MessageContentType, Conversation, Participant } from "@chatscope/use-chat";
import type {User, UserId, ConversationId} from "@chatscope/use-chat"
import {Lilly, Feedback, Martian, Elliot, Help} from "./users";

export const predefinedUsers = [
  Lilly,
  Elliot,
  Feedback,
  Martian,
  Help
];

const createConversation = ( {id}:User, description?:string, readonly:boolean = false) =>
    new Conversation({
        id,
        participants: [
            new Participant({id})
        ],
        readonly,
        description
    });

export const predefinedContacts = [
    createConversation(Lilly, "Talk to me...."),
    createConversation(Elliot, "I'm a good listener"),
    createConversation(Feedback, "Do you like this app?"),
    createConversation(Martian, "I am an alien life form", true),
    createConversation(Help, "Be sure to see what's here...", true)
];

const createMessage = (payload:string, senderId:UserId, conferenceId: ConversationId, direction:string, type = MessageContentType.TextHtml) => ({ conferenceId: conferenceId, message: { senderId, direction, model: {type, payload} } });

export const predefinedMessages = [
  "Hi :username:!",
  "I'm not really a bot &#x1F604",
  "but you can talk to me, without any consequences to test this awesome app",
  "I will listen you patiently :)",
  "Cheers!"
].map(t => createMessage(t, Lilly.id,  Lilly.id,"incoming")
).concat([
    "Hey :username:, I'm Eliot",
    "Nice to see you here!",
    "Just like Lilly, I'm not a real bot and I won't answer you anything :)",
    "but I can be a really good listener",
    "Can you tell me an interesting story?"
].map( t => createMessage(t, Elliot.id, Elliot.id,"incoming") )).concat([
  "Hello :username:!",
  "Have you ever talked to a Martian?",
  "If not, you should try it!",
  "I'm not here at the moment, but you can contact me via Martian chat using this link:",
  "<a href=\"https://mars.chatscope.io/?r=spp\" target=\"_blank\" rel=\"noopener\">https://mars.chatscope.io/</a>"
  ].map( t => createMessage(t, Martian.id, Martian.id,"incoming") )
);

export const feedbackPredefinedMessages = [
  "Hi :username:!",
  "You can leave feedback about chat components here",
  "Please enter your e-mail address if you want to receive a reply from us",
  "If you wish to remain anonymous, just enter your comments",
  "Providing an email is optional",
  "We appreciate all your feedback!"
  ].map(t => createMessage(t, Feedback.id, Feedback.id, "incoming"));

