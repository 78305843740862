import { ActionTypes as ConversationActionTypes, SetTypingUser } from "../actions/conversation.actions"
import { debounce } from "lodash";

/**
 * @param {Number} wait How many ms to wait before debouncing typing
 * @returns {function(*): function(*=): function(...[*]=)}
 */
const typingDebounceMiddleware = (wait) => {

    // Map keyed by conversationId
    // As value there is object of user id -> debounce
    const debounces = new Map();
    
    const removeEntryAndDispatch = (conversationId, userId, next) => {
        
        const conversation = debounces.get(conversationId);
        
        if ( typeof  conversation !== "undefined" ) {
            
            const entries = Object.keys(conversation);
            
            // If conversation have more than one typing entry
            // delete it
            if (entries.length > 1) {
                delete conversation[userId];
            } else { // in other case remove whole conversation from map
                debounces.delete(conversationId);
            }

          return next(SetTypingUser({conversationId, userId, typing: false}));
            
        }
    };
    
    return store => next => action => {
      
      const { type, payload: { conversationId, userId, typing } = {} } = action; // Payload can be undefined
      
        if( type === ConversationActionTypes.SetTypingUser ) {
            
            if ( typing === true ) {
                
                const factory = (conversationId, userId, next) =>
                    debounce(() => {

                        removeEntryAndDispatch(conversationId, userId, next);

                    }, wait, {leading: false});

                const conversation = debounces.get(conversationId);
                if (typeof conversation !== "undefined") {
                    
                    const debounceEntry = conversation[userId];

                    if (typeof debounceEntry !== "undefined") {
                        debounceEntry();
                    }

                    // Dont't forward action, because we don't want additional rendering and calculations

                } else {

                    const debouncedFnc = factory(conversationId, userId, next);
                    
                    debouncedFnc();

                    const newEntry = {
                        [userId]: debouncedFnc
                    };

                    debounces.set(conversationId, newEntry);
                    
                    return next(action);

                }
            } else {
                removeEntryAndDispatch(conversationId, userId, next);
            }
            
        } else {
            return next(action);
        }
        
    }
    
};

export default typingDebounceMiddleware;