import PropTypes from "prop-types";

import { Chat } from "../../components";
import noop from "../../utils/noop";

export const ChatView = ({user, conversations, messages, activeConversation, onSend, onLogout, onBack,
                           onConversationChange, onChange, activeSetBy, hint,
                           suggestionsEnabled,
                           currentUserDisconnected, animating,
                           hideFakeChatContainer,
                           onReward
                         }) => {
       
    const handleConversationChange = (evt, c) => onConversationChange(c);
    
    return <Chat animating={animating}
                 user={user} conversations={conversations} messages={messages}
                 onChange={onChange}
                 onSend={onSend}
                 onLogout={onLogout} onBack={onBack} onConversationChange={handleConversationChange}
                 onReward={onReward}
                 activeConversation={activeConversation}
                 activeSetBy={activeSetBy}
                 hint={hint}
                 suggestionsEnabled={suggestionsEnabled}
                 currentUserDisconnected={currentUserDisconnected}
                 hideFakeChatContainer={hideFakeChatContainer}
    />;
    
};

ChatView.displayName = "ChatView";

ChatView.propTypes = {
  user: PropTypes.shape({ id: PropTypes.string, username: PropTypes.string, firstName: PropTypes.string, lastName: PropTypes.string, statusDescription: PropTypes.string } ),
  conversations: PropTypes.array,
  messages: PropTypes.array,
  activeSetBy: PropTypes.string,
  hint: PropTypes.shape({
      source: PropTypes.string,
      suggestion: PropTypes.string
  }),
  suggestionsEnabled: PropTypes.bool,
  hideFakeChatContainer: PropTypes.bool,
  currentUserDisconnected: PropTypes.bool,
  onLogout: PropTypes.func,
  onBack: PropTypes.func,
  onSend: PropTypes.func,
  onChange: PropTypes.func,
  onConversationChange: PropTypes.func,
  activeConversation: PropTypes.object,
  onReward: PropTypes.func
};

ChatView.defaultProps = {
    conversations: [],
    messages: {},
    suggestionsEnabled: false,
    hideFakeChatContainer: false,
    currentUserDisconnected: false,
    onLogout: noop,
    onBack: noop,
    onSend: noop,
    onConversationChange: noop,
    onChange:noop,
    onReward: noop
};

export default ChatView;