export const gridBreakpoints = {
  xs: "0",
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  xxl: "1400px"
};


export const smallScreenMQ = `(max-width: ${gridBreakpoints.sm})`;