import {useState, useCallback } from "react";
import { ChatView } from "../views/";
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
// TODO: SetCurrentUserDisconnected na pewni musi wylecieć jako state aplikacji a nie chatu 
import {
  SetActiveSetBy,
  SetCurrentUserDisconnected
} from "../headless-chat/storage/redux/actions/conversation.actions";
import {cleanMsg} from "../utils/sanitize";
import {useServices} from "../context-providers/ServicesProvider";
import { batch } from "react-redux";
import PropTypes from "prop-types";
import {SetHideFakeChatContainer} from "../redux/actions/app.actions";
import { useObservable, useHintFilterObservable, useHintObservable } from "../hooks";
import { validateEmail} from "../utils/validators";
import {MessageContentType, MessageDirection, MessageStatus, ChatMessage, useChat} from "@chatscope/use-chat";
import {isAwardWinningWord} from "../utils/isAwardWinning";
import {useConfig} from "../init";
import {useLogout} from "../hooks/useLogout";

export const ChatContainer = ({animating}) => {
     
    const {config} = useConfig();
    
    const handleLogout = useLogout();
    const [hint, setHint] = useState({source:"", recipientName: "", suggestion: undefined, originalSuggestion: undefined });
    const {creatext: creatextService} = useServices();
    
    const [hintFilterSubject, hintFilterObservable] = useHintFilterObservable();
    const [toggleHintSubject, getHintSubject, getHintObservable] = useHintObservable(creatextService, hintFilterSubject);
    
    const getHintCallback = useCallback( (hint) => {
      setHint(hint);
      getHintSubject.next(hint);
    },[setHint, getHintSubject]);
    
    useObservable(hintFilterObservable, getHintCallback );
    useObservable(getHintObservable, setHint);
    
    const dispatch = useDispatch();
    
    const [activeSetBy, currentUserDisconnected, hideFakeChatContainer] = useSelector( state => {       
        return [           
            state.chat.conversations.activeSetBy,
            state.chat.conversations.currentUserDisconnected,
            state.app.hideFakeChatContainer,
        ]
    });
    
    const { conversations, currentMessages: messages, activeConversation, setActiveConversation, sendMessage, setDraft, addMessage, sendTyping, 
      currentUser, setCurrentMessage } = useChat();
    
  const handleBack = e =>  {
        
      // There is not needed to save a draft here, as 
      // the current conversation is unset after entering /conversation route which causes save a draft by auto-draft mechanism
      
      batch(() => {
        dispatch(SetCurrentUserDisconnected(false));
        dispatch(SetHideFakeChatContainer(true));
        dispatch(push("/conversations"));
      });
               
    };

    const handleConversationChange = conversation => {
      dispatch(SetActiveSetBy("click"));
      setActiveConversation(conversation.id);
    };
    
    const handleChange = msg => {
      
      // TODO: Suggestions only when there is max one participant in the conversation
      if ( config?.suggestionsEnabled === true ) {
        
        toggleHintSubject.next(true);
        
        // TODO: Fix recipient name - get from participants
        hintFilterSubject.next({msg, hint, recipientName: activeConversation?.name ?? ""});
        
      }
        
      sendTyping( {});
      setCurrentMessage(msg);
        
    };
    
    const handleSend = messageText => {
        
      // Pause getting hint (hints received after send will not applied till next onchange event
      // TODO: Ale, co będzie, jeżeli przyleci event odpowiedzi jeszcze po tym, jak zacznę pisać ale wysłany do serwera zanim wysłałem wiadomość?
      // Chyba powinien być jeszcze mechanizm, który od zdiscarduje wiadomości od pewnego momentu
      toggleHintSubject.next(false);       
      console.log(`%cSENDING: ${messageText}`, 'color: green');
      const replacedText = cleanMsg(messageText);
      console.log(`%cSENDING: ${replacedText}`, 'color: green');
      
      setHint({
          source: "",
          suggestion: undefined
      });
       
      setDraft("");

      const message = {
          id: "", // Id will be generated by storage generator, so here you can pass an empty string
          content: replacedText,
          contentType: MessageContentType.TextHtml,
          senderId: currentUser.id,
          direction: MessageDirection.Outgoing,
          status: MessageStatus.Sent
      };
      
      // Reward flag - Chat component renders ConfettiMessage based on this flag
      if ( isAwardWinningWord(replacedText) ) {
        message.reward = true;
      }
        
      sendMessage({
        message, 
        conversationId: activeConversation.id,
        senderId: currentUser.id,
        generateId: true
      });

      // Check if message is an email address in feedback conversation
      if ( activeConversation.id === "feedback-1" && validateEmail(replacedText) === true ) {
        
        const rewardMessage = new ChatMessage({
          contentType:MessageContentType.TextPlain,
          content: "Thank you!",
          direction: MessageDirection.Incoming,
          senderId: "feedback-1",
          status: MessageStatus.Seen
        });
        
        // Reward flag - Chat component renders ConfettiMessage based on this flag 
        rewardMessage.reward = true;
        addMessage(rewardMessage, activeConversation.id, true);
        
      } 
        
    };
    
    return <ChatView animating={animating}
              user={currentUser} conversations={conversations} messages={messages}
              activeConversation={activeConversation}
              hint={hint}
              suggestionsEnabled={config?.suggestionsEnabled ?? false}
              hideFakeChatContainer={hideFakeChatContainer}
              activeSetBy={activeSetBy}
              currentUserDisconnected={currentUserDisconnected}
              onSend={handleSend}
              onChange={handleChange}
              onLogout={handleLogout} onBack={handleBack}
              onConversationChange={handleConversationChange} />;
    
};

ChatContainer.propTypes = {
  animating: PropTypes.bool
};

ChatContainer.defaultProps =  {
  animating: false
};

export default ChatContainer;