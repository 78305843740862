import { actionCreator } from "./actionCreator";

const USER_ACTION = "UserData/";

export const ActionTypes = {
        SetUsername : USER_ACTION + "SetUsernameState",
        SetFirstName: USER_ACTION + "SetFirstNameState",
        SetLastName: USER_ACTION + "SetLastNameState",
        SetStatus: USER_ACTION + "SetStatus",
        SetStatusDescription: USER_ACTION + "SetStatusDescription",
        SetUserData: USER_ACTION + "SetUserData",
        SetPermissions: USER_ACTION + "SetPermissions",
        SetAvatar: USER_ACTION + "SetAvatar"
};

export const SetUsername = actionCreator(ActionTypes.SetUsername);
export const SetFirstName = actionCreator(ActionTypes.SetFirstName);
export const SetLastName = actionCreator(ActionTypes.SetLastName);
export const SetStatus = actionCreator(ActionTypes.SetStatus);
export const SetStatusDescription = actionCreator(ActionTypes.SetStatusDescription);
export const SetUserData = actionCreator(ActionTypes.SetUserData);
export const SetPermissions = actionCreator(ActionTypes.SetPermissions);
export const SetAvatar = actionCreator(ActionTypes.SetAvatar);
