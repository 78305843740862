import { actionCreator } from "./actionCreator";

const APP_ACTION = "App/";

export const ActionTypes = {
        SetHideFakeChatContainer : APP_ACTION + "SetHideFakeChatContainer",
        ShowNewAppVersionNotification: APP_ACTION + "ShowNewAppVersionNotification"
};

export const SetHideFakeChatContainer = actionCreator(ActionTypes.SetHideFakeChatContainer);
export const ShowNewAppVersionNotification = actionCreator(ActionTypes.ShowNewAppVersionNotification);