import {useEffect} from "react";
import {RoutesContainer} from "./containers";
import './themes/default/main.scss';
import {useMediaQuery} from "./hooks";
import {smallScreenMQ} from "./utils/responsive";
import {push} from 'connected-react-router'
import {batch, useDispatch, useSelector, useStore} from 'react-redux'
import {SetHideFakeChatContainer, ShowNewAppVersionNotification} from "./redux/actions/app.actions";
import NewAppVersionNotification from "./components/NewAppVersionNotification";
import {Config, useConfig} from "./init";
import {useInitializeChatService} from "./hooks/useInitializeChatService";
import {CommonChatService} from "./services/ChatServiceStrategy";
import {SimpleChatAdapter} from "@chatscope/simple-chat-adapter";
import {SetAuthState} from "./redux/actions/auth.actions";
import {UserStatus} from "@chatscope/use-chat";

function App() {

    const dispatch = useDispatch();
    const store = useStore();

    // TODO: Tutaj jest lipa, bo jak będzie error, to się najpewniej zapętli!!
    const {config, hasConfig, error} = useConfig();
    
    useEffect(() => {
        if ( error ) {
            console.error(error);
        }
    }, [error]);

    // Initialize chat api
    const {chatService} = useInitializeChatService();
    
    useEffect(() => {
      
      const process = async(chatService:CommonChatService, config: Config) => {

        if (config.adapter.name === "simple-chat") {

          const service = chatService as unknown as SimpleChatAdapter;

          // Poebiram token
          // Jeżeli go mam, to mogę się zarejestrować
          const getTokenReponse = await service.getToken();
            console.log("Token response", getTokenReponse);
            
            if (getTokenReponse.result) {
              await service.connect();

              const userData = await service.getUserData();

              console.log(userData);

              // TODO: Kondon, ale trzeba te typy ogarnąć, jak będzie typ response z serwera w adapterrze
              // Ale w sumie to nie powinno iść z adaptera, tylko z serwera?
              const user = userData as any;
              
              console.log("Rejestruję");
              const registerResponse = await service.register({
                id: user.id,
                username: user.username,
                avatar: user.avatar,
                status: UserStatus.Available 
              });
              
              // TODO: Dodanie predefiniowanych kontaktów
              // TO chyba powinno być jako jakaś reakcja na jakieś zdarzenie, albo jakiś osobny hook?
              // Bo teraz jest to dwa razy... raz tutaj, a drugi raz po zalogowaniu
              
              console.log("Register response", registerResponse);
              await service.getContacts();
              
              dispatch(SetAuthState(true));
              
              service.getConversations();
              
          }
        }
      }
      
      // TODO: Return value from process and dispatch in then
      if ( config && chatService ) {
        process(chatService, config);
      }
      
    },[chatService, config, dispatch]);
  
    const [loggedIn, showNewVersionNotification, onConfirm] = useSelector(state => (
        [
            /* @ts-ignore */
            state.auth.loggedIn,
            /* @ts-ignore */
            state.app.showNewAppVersionNotification.show,
            /* @ts-ignore */
            state.app.showNewAppVersionNotification.onConfirm
        ]
    ));

    // Check screen size
    // For big screen navigate to chat
    // For small screen navigate to conversations   
    const match = useMediaQuery(smallScreenMQ);

    useEffect(() => {
        if (loggedIn === true) {
            if (match === false) {
                batch(() => {
                    dispatch(push("/chat"));
                    dispatch(SetHideFakeChatContainer(false));
                });
            } else {
                // Show conversations list if no chat is active
                const activeConversation = store.getState().chat.conversations.active;
                if (typeof activeConversation === "undefined") {
                    batch(() => {
                        dispatch(SetHideFakeChatContainer(true));
                        dispatch(push("/conversations"));
                    });
                }
            }
        } else {
            dispatch(push("/login"));
        }

    }, [loggedIn, match, store, dispatch]);

    const handleNotificationClose = () => {
        dispatch(ShowNewAppVersionNotification(true));
    };

    const handleInstallClick = () => {
        if (typeof onConfirm === "function") {
            onConfirm();
        }
    };

    return (
        <div className="w-100 h-100 overflow-hidden position-relative">
            <NewAppVersionNotification show={showNewVersionNotification} onClose={handleNotificationClose}
                                       onInstallClick={handleInstallClick}/>
            {hasConfig  && <RoutesContainer/>}
        </div>

    );
}

export default App;
