import React from "react";
import Alert from "react-bootstrap/Alert";
import PropTypes from "prop-types";
import noop from "../../utils/noop";
import Button from "react-bootstrap/Button";

export const InstallBanner = ({onInstallClick, onDismissClick }) => {

    return <div className="install-banner"><Alert variant="info" className="mb-0 text-center">
        
        <Button type="button" variant="danger" onClick={onDismissClick}>Dismiss</Button>
        <Button type="button" variant="primary" className="ml-4" onClick={onInstallClick}>Add to home screen</Button>
        
    </Alert>
    </div>
    
};

InstallBanner.propTypes =  {
  onInstallClick:   PropTypes.func,
  onDismissClick: PropTypes.func
};

InstallBanner.defaultProps = {
    onInstallClick: noop,
    onDismissClick: noop
};

export default InstallBanner;