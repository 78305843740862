import {Context, createContext, ReactNode, useCallback, useContext, useEffect, useRef, useState} from "react";

// Thanks to
// https://ordina-jworks.github.io/architecture/2021/02/12/react-generic-context.html

// Typescript must know the name of the custom event
// https://stackoverflow.com/questions/68579484/how-to-handle-event-type-and-customevent-type-on-eventlisteners-in-typescri
declare global {
  interface WindowEventMap {
    "config.received": CustomEvent;
  }
}

interface ConfigProviderProps {
  children: ReactNode
  configPath: string;
  timeout?: number;
  enabled?: boolean;
}

// C extends any because our config can be anything
export interface ConfigProviderContextProps<C extends any> {
  config?: C;
  loading: boolean;
  hasConfig: boolean;
  error?: any;
}

// TODO: Config mógłgby też wykorzystywać Suspense???
// TODO: W sumie to by mogło być jeszcze bardziej generyczne, zeby mozna było tworzyć jakiekolwiek generyczne prvidery
// Create typed context
const createConfigContext = <C extends any>() => createContext<ConfigProviderContextProps<C>>({
  loading: false,
  hasConfig: false,
  config: undefined,
  error: undefined
});


// Private API
// Create hook
export const createUseConfig = <C extends any>(configContext: Context<ConfigProviderContextProps<C>>) => {
  return () => {
    const context = useContext(configContext);
    if (!context) {
      throw new Error("useConfig must be within ConfigProvider");
    }
    return context;
  }
}

const errorMgs = "Error loading config file";

// Private API
// Create provider
export const createConfigProvider = <C extends any>(configContext: Context<ConfigProviderContextProps<C>>) => {

  return ({children, configPath, enabled = true, timeout = 5000}: ConfigProviderProps) => {

    const waitingForConfig = useRef(false);
    const timeoutRef = useRef<number>();
    const [config, setConfig] = useState<C>();
    const [loading, setLoading] = useState(enabled);
    const [hasConfig, setHasConfig] = useState(false);
    const [error, setError] = useState<any>(undefined);
    
    const getConfig = useCallback(() => {

      let configReceived = false;
      setLoading(true);

      const head = document.head;

      const script = document.createElement("script");

      script.src = configPath;
      const evtName = "config.received";
      const handleConfigReceived = (evt: CustomEvent) => {

        configReceived = true;
        window.clearTimeout(timeoutRef.current);
        head.removeChild(script);
        window.removeEventListener(evtName, handleConfigReceived);
        setConfig(evt.detail);
        setHasConfig(true);
        setLoading(false);

      };

      script.addEventListener("load", (evt) => {

        // Start timeout for config event
        if (configReceived === false) {
          timeoutRef.current = window.setTimeout(() => {

            timeoutRef.current = undefined;
            setError(`Waiting for config time (${timeout}ms) exceeded`);
            window.removeEventListener(evtName, handleConfigReceived);

          }, timeout);

        }

      });

      // Catching script errors e.g. 404, exceptions threw by config file etc.
      script.addEventListener("error", (err) => {
        console.error(errorMgs, err);
        setError(err);
      });

      window.addEventListener("error", (err) => {

        const url = new URL(configPath, configPath.startsWith("http://") || configPath.startsWith("https://") ? undefined : window.location.origin);

        if (err.filename.endsWith(url.href)) {
          console.error(errorMgs, err);
          setError(err);
        }

      });

      window.addEventListener(evtName, handleConfigReceived);

      head.appendChild(script);


    }, [setLoading, setConfig, setHasConfig, configPath, timeout, timeoutRef]);

    useEffect(() => {
      if (enabled === true && waitingForConfig.current === false) {
        waitingForConfig.current = true;
        getConfig();
      }
    }, [enabled, waitingForConfig, getConfig]);

    return (
      <configContext.Provider value={{
        config, loading, hasConfig, error
      }}>
        {typeof children === "function" ? children() : children}
      </configContext.Provider>
    );

  };

}

// Public API - Wrapper
export const createProvider = <C extends {}>() => {

  const ConfigContext = createConfigContext<C>();
  ConfigContext.displayName = "ConfigContext";

  const useConfig = createUseConfig<C>(ConfigContext);

  const Provider = createConfigProvider<C>(ConfigContext);

  return {
    useConfig,
    Provider
  }

}