import {useChat} from "@chatscope/use-chat";

// TODO: Może to być w libie od płatnych komponentów
// To czy jest sens?
// Chyba, że to by zwracało tych userów np na zmianę, jeżeli jest ich więcej 
// Może mechanizm powinien wyglądać tak, że jak się dodaje nowego istniejącego to on wlatuje zawsze na koniec tablicy?
// A tutaj brać z końca... wtedy będzie rotacja...
// Tylko, ta rotacja musiała by też być jakoś czasowo, zeby się nie okazało, że to mryga jak na raz piszą ludzie
// To hook powinien to sam rotować,  to by było całkiem niezłe i wtedy miałby rację bytu jako fajne narzędzie,
// które zdejmuje sporo dziwnych rzeczy z programisty

/**
 * @param conversationId
 * @param fnc Function receiving currentTyping user as parameter. Value returned from this function will be returned by the hook
 * @returns {null|{content: *}}
 */
export const useTypingIndicator = (conversationId, fnc) => {
  
  const { getConversation, getUser } = useChat();
  
  if ( conversationId ) {
    const conversation = getConversation(conversationId);

    if (conversation) {
      
      const typingUsers = conversation.typingUsers;
      
      if (typingUsers.length > 0) {

        const typingUserId = typingUsers.items[0].userId;

        // Check if typing user participates in the conversation
        if (conversation.participantExists(typingUserId)) {
          
          const typingUser = getUser(typingUserId);

          if (typingUser) {

            return {
              content: fnc(typingUser)
            };
            
          }

        }

      }

    }
    
  }

  return undefined;
  
};