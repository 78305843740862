import {createProvider as createConfigProvider} from "../context-providers/config/ConfigProvider";

// Structure of the object dispatched by configuration file /config.js
export interface Config {
  adapter: {
    name: string,
    options: {
      server: string,
      api: string;
    },
  },
}

const { Provider, useConfig } = createConfigProvider<Config>()

export { Provider, useConfig };