import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router"
import { createRootReducer } from "./redux/reducers";
import thunk from 'redux-thunk';
//import chatServiceMiddleware from "./redux/middleware/ChatServiceMiddleware";
import typingDebounceMiddleware from "./headless-chat/storage/redux/middelware/TypingDebounceMiddleware";
import appMiddleware from "./redux/middleware/AppMiddleware";

export const history = createBrowserHistory();

//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 }) || compose;

export default function configureStore(preloadedState) {
    const store = createStore( 
      createRootReducer(history), // root reducer with router state
        preloadedState,
        compose(
//      composeEnhancers(
            applyMiddleware(
                routerMiddleware(history), // for dispatching history actions
                thunk,
                appMiddleware(),
                typingDebounceMiddleware(900), // Debounce to 900ms this is reasonable value
                //chatServiceMiddleware
                // ... other middlewares ...
            ),
        ),
    );

    return store
}