import {useState } from "react";
import { Container } from "react-bootstrap";
import {Login } from "../../components";
import {Branding } from "./Branding";
import {NotAvailableModal} from "./NotAvailableModal";
import {LoginData} from "../../components/Login";
// Lazy breaks autofocus because of repaint, so focus should be restored after load
//const Branding = lazy(() => import("./Branding"));

interface LoginViewProps {
    onLoginClick?: (data:LoginData) => void;
    inputsDisabled?: boolean;
    loading?: boolean;
    showPassword?: boolean;
    showInfo?: false;
    forgotPassword?: boolean;
    usernamePlaceholder?: string;
    passwordPlaceholder?: string;
    register?: boolean;
    onUserIconClick?: () => void;
    invalidCredentials?: boolean;
}

export const LoginView = ({ onLoginClick,
                            inputsDisabled = false,
                            loading = false,
                            showPassword = true,
                            showInfo = false,
                            forgotPassword = false,
                            register = false,
                            usernamePlaceholder,
                            passwordPlaceholder,
                            onUserIconClick,
                            invalidCredentials = false
                          }:LoginViewProps) => {
  
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    
    const handleRegisterClick = () => setShowModal(true);
    const handleForgotPasswordClick = () => setShowModal(true);
    
    return (<Container className="d-flex flex-grow-1 flex-column justify-content-center align-items-center">
        <NotAvailableModal show={showModal} onHide={handleClose} />  
        <Login 
               onLoginClick={onLoginClick}
               showPassword={showPassword}
               showInfo={showInfo}
               forgotPassword={forgotPassword}
               register={register}
               usernamePlaceholder={usernamePlaceholder}
               passwordPlaceholder={passwordPlaceholder}
               inputsDisabled={inputsDisabled}
               onRegisterClick={handleRegisterClick}
               onForgotPasswordClick={handleForgotPasswordClick}
               loading={loading}
               onUserIconClick={onUserIconClick}
               branding={<Branding/>}
               invalidCredentials={invalidCredentials}
        />
    </Container>); 
    
};

LoginView.displayName = "LoginView";


export default LoginView;