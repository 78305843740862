/* @ts-ignore */
import {Col, Container, Row} from "react-bootstrap";
import {DemoFeatures} from "./DemoFeatures";
import {FAQ} from "./FAQ";
import {ContactAndResources} from "./ContactAndResources";
import {AboutAuthor} from "./AboutAuthor";

export const HelpConversation = () => {
    return (
        <div className="d-flex flex-column flex-grow-1">
            <Container fluid className="pb-3 pl-4">
                <Row>
                    <Col xs="12" md="6" className="pr-sm-5">
                       <DemoFeatures />
                    </Col>
                    <Col  xs="12" md="6">
                        <ContactAndResources />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FAQ />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <AboutAuthor className="mb-4"/>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default HelpConversation;