import { Route } from "react-router-dom";

export const AppRoute = ({view: View, animating, ...rest}) => {
  
    return (
      <Route {...rest}>       
            <View animating={animating}/>
      </Route>
    );
    

};

export default AppRoute;