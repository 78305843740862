//import chatscopeLogo from "./chatscope_logo_alpha.svg";

export const ChatKitty = () => {
    return (
        <div>
          <p>UI components provided by</p>
          <div>
            <a href="https://chatkitty.com" rel="noopener noreferrer" target="_blank">
              <img src="" alt="ChatKitty logo" style={{maxWidth:"200px"}}/>
            </a>
          </div>
        </div>
    );
};

ChatKitty.propTypes = {};

ChatKitty.defaultProps = {};

export default ChatKitty;