import { reducer as messagesReducer } from "./message.reducer";
import { reducer as conversationsReducer } from "./conversation.reducer";

export const reducer = (state, action) => {
  
  const messages = messagesReducer(state, action);
  const conversations = conversationsReducer(state, action);
  
  return { messages, conversations};
  
};